import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MeetImage from '../assets/images/meet_vacaytion-image.jpg';
import { Carousel } from 'primereact/carousel';
        
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css';

const MeetVacaytions = () => { 
    const [travelers, setTravelers] = useState([]);

    const listItems = [
        <div className='meetVacaytionSlide' key={1}>אספנו את <strong>המטיילים, המומחים ומתכנני החופשות הכי טובים מכל רחבי הרשת</strong>, וביקשנו מהם לבנות מסלולי טיול שהם לא פחות מ-וואו.</div>,
        <div className='meetVacaytionSlide' key={2}>בכל תיק טיול תמצאו <strong>ה - כ - ל</strong>  החל ממסלולים, דרך אטרקציות לכל יום, מסעדות מעולות, השופינג הכי שווה ועד הפרטים הקטנים שלא ידעתם שאתם צריכים (למשל- איפה הכי כדאי להחליף כסף)</div>,
        <div className='meetVacaytionSlide' key={3}>כל זה, <Link to=''>מרוכז במקום אחד ואיתכם בלייב כל הטיול</Link>. הכי בקלות והכי נח שאפשר. פשוט ככה. כי חופשה מושלמת לא צריכה להיות עבודה.</div>
    ];

    const itemTemplate = (item) => {
        return (
            <div className="list-item">
                {item}
            </div>
        );
    };
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    return (
        <div className='meetVacaytions'>
            <div className='container'>
                <>
                {isMobile && (
                    <h2 className='mb-4 mb-md-5 pb-2 pb-md-4 text-center'>תכירו את VACAYTIONS</h2> 
                )}
                </>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='meetVacaytionText ps-0 ps-md-4'>
                            {!isMobile && (
                            <h2 className='mb-3 mb-md-5 pb-2 pb-md-4'>תכירו את VACAYTIONS</h2>
                            )}
                            {isMobile ? (
                            <div className='meetVacaytionTextSlider'>
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    pagination={{ clickable: true }}
                                    rtl={true}
                                    autoplay={{ delay: 2500, disableOnInteraction: false }}
                                    modules={[Navigation, Pagination, Autoplay]}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                >
                                    <SwiperSlide>
                                        <div className='meetVacaytionSlide' key={1}>אספנו את <strong>המטיילים, המומחים ומתכנני החופשות הכי טובים מכל רחבי הרשת</strong>, וביקשנו מהם לבנות מסלולי טיול שהם לא פחות מ-וואו.</div>                                    
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='meetVacaytionSlide' key={2}>בכל תיק טיול תמצאו <strong>ה - כ - ל</strong>  החל ממסלולים, דרך אטרקציות לכל יום, מסעדות מעולות, השופינג הכי שווה ועד הפרטים הקטנים שלא ידעתם שאתם צריכים (למשל- איפה הכי כדאי להחליף כסף)</div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='meetVacaytionSlide' key={3}>כל זה, <Link to=''>מרוכז במקום אחד ואיתכם בלייב כל הטיול</Link>. הכי בקלות והכי נח שאפשר. פשוט ככה. כי חופשה מושלמת לא צריכה להיות עבודה.</div>
                                    </SwiperSlide>
                                </Swiper>
                                {/* <Carousel 
                                    value={listItems} 
                                    numVisible={1} 
                                    numScroll={1} 
                                    showIndicators={true} 
                                    autoplayInterval={3000} 
                                    itemTemplate={itemTemplate} 
                                /> */}
                            </div>
                            ) : (
                            <ul className='position-relative'>
                                <li>אספנו את <strong>המטיילים, המומחים ומתכנני החופשות הכי טובים מכל רחבי הרשת</strong>, וביקשנו מהם לבנות מסלולי טיול שהם לא פחות מ-וואו. </li>
                                <li>בכל תיק טיול תמצאו <strong>ה - כ - ל</strong>  החל ממסלולים, דרך אטרקציות לכל יום, מסעדות מעולות, השופינג הכי שווה ועד הפרטים הקטנים שלא ידעתם שאתם צריכים (למשל- איפה הכי כדאי להחליף כסף)
                                </li>
                                <li>כל זה, <Link to=''>מרוכז במקום אחד ואיתכם בלייב כל הטיול</Link>. הכי בקלות והכי נח שאפשר. פשוט ככה. כי חופשה מושלמת לא צריכה להיות עבודה.
                                </li>
                            </ul>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='meetVacaytionImg text-right position-relative'>
                            <img src={MeetImage} alt='Left Image' />
                            <a href={`${process.env.REACT_APP_API_URL}/search-vacations/`} className='btn-primary position-absolute'>צפייה במסלולים</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MeetVacaytions;
