import { useEffect, useState } from "react";
import CalendarIcon from "../assets/icons/CalendarIcon";
import EyeIcon from "../assets/icons/EyeIcon";
import { getActivityTypeIcons } from "../utils/getIcon";
import { useSelector } from "react-redux";

const DaysActivity = ({data,openCalendar, setIsCalendarLoading, setMainLoader}) => {
    const [specificDaysData, setSpecificDaysData] = useState([]);
    const { listingData } = useSelector((state) => state.listingData);

    const handleScrolling = (key) =>{
        const keyPeices = key.split("_");
        const element = document.querySelector(`.singleRepeater.${keyPeices[0]}_${keyPeices[1]}${keyPeices[2]}`);
        const yOffset = -300;
        const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
    }

    const getAllDaysData = () => {
        var itemsData = []
        if(listingData?.acf){
            Promise.all(listingData?.acf?.rz_day_activity && listingData?.acf?.rz_day_activity.map((item, key) => {
                if(item?.rz_day_activity_list){
                    Promise.all(item?.rz_day_activity_list.map((listItem, listKey) => {
                        let rzPeices = listItem?.day_recommendation !== "" && listItem?.day_recommendation?.value.split("_");
                        if(rzPeices){
                            let itemData = {}
                            let rzName = `${rzPeices[0]}_${rzPeices[1]}`;
                            itemData["rzName"] = rzName;
                            itemData[rzName] = [listingData.acf[rzName][rzPeices[2]]];
                            itemData["index"] = rzPeices[2];
                            itemData["startTime"] = listItem.rz_day1_activity_time;
                            itemData["day"] = key;
                            itemData["day_recommendation"] = true;

                            itemsData.push(itemData)
                        }else{ 
                            let itemData = {}
                            itemData["title"] = listItem.rz_day2_activity_info;
                            itemData["startTime"] = listItem.rz_day1_activity_time;
                            itemData["day"] = key;
                            itemData["day_recommendation"] = false;

                            itemsData.push(itemData)
                        }
                    }))
                }
            }))
        }

        setSpecificDaysData(itemsData)
    }

    useEffect(() => {
        getAllDaysData();
    }, [listingData]);

    return (
        <div className="daysActivityData">
            {data?.acf?.rz_day_activity && data?.acf?.rz_day_activity.map((item, key) => (
                <div className="singleDayData" key={`day-${key+1}`}>    
                    <div className="singleListingPanelSummary dayTitle mb-3 reapterTitle">
                        <h5>Day - {key+1} {item?.rz_day1_activity_title}</h5>
                        {listingData?.id !== 17147 && (
                            <div className="addAllToEvent">
                                <CalendarIcon 
                                    height={40} 
                                    width={40}  
                                    itemData={specificDaysData}
                                    addAll={true}
                                    day={key}
                                    openCalendar={openCalendar}
                                    setIsCalendarLoading={setIsCalendarLoading}
                                    setMainLoader={setMainLoader}
                                />
                            </div>
                        )}
                    </div>

                    <hr />
                    <div className="singleListingPanelSummary">
                        <div className="dayActivityList">
                            {item?.rz_day_activity_list && item?.rz_day_activity_list.map((listItem, listKey) => (
                                <div className="dayActivity d-flex align-items-center justify-content-between" key={`singleList_${listKey}`}>  
                                    <div className="dayListText ps-3">
                                        <div
                                            className="dayActivityListIcon"
                                            dangerouslySetInnerHTML={{
                                                __html: getActivityTypeIcons(listItem?.rz_day1_activity_type) && getActivityTypeIcons(listItem?.rz_day1_activity_type)[listItem?.rz_day1_activity_type]
                                            }}
                                            />
                                        <div className="d-flex gap-2 align-items-center">                                        
                                            <b>{listItem?.rz_day1_activity_time}</b>
                                            <p className="mb-0">- {listItem?.rz_day2_activity_info != "" ? listItem?.rz_day2_activity_info : listItem?.day_recommendation?.label}</p>
                                        </div>
                                    </div>
                                    <div className="listDayLeftIcons d-flex gap-2 align-items-center">
                                        {listItem?.day_recommendation != "" && (
                                            <div className={`scrollEyeIcon`} onClick={() => handleScrolling(listItem?.day_recommendation?.value)} >
                                                <EyeIcon />
                                            </div>
                                        )}

                                        {listingData?.id !== 17147 && (

                                            <CalendarIcon 
                                                name={listItem?.rz_day2_activity_info} 
                                                height={40} 
                                                width={40} 
                                                otherFields={true} 
                                                startTime={listItem?.rz_day1_activity_time} 
                                                accId={listItem?.day_recommendation !== "" ? listItem?.day_recommendation?.value.split("_")[0] + "_" + listItem?.day_recommendation?.value.split("_")[1] : ""}
                                                index={listItem?.day_recommendation !== "" ? listItem?.day_recommendation?.value.split("_")[2] : ""}
                                                disableTitle={true}
                                                openCalendar={openCalendar}
                                                isDaysActivity={true}
                                                setIsCalendarLoading={setIsCalendarLoading}
                                                setMainLoader={setMainLoader}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DaysActivity;