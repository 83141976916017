import LoaderImg from '../assets/images/loader.gif';

const Loader = () => {
    return (
        <div className="loaderWrapper d-flex align-items-center justify-content-center">
            <div>
                <img src={LoaderImg} alt="Loader Image" />
                <p style={{color: "#fff"}}>אנא המתינו בסבלנות, התהליך עלול לקחת מספר שניות :)</p>
            </div>
            {/* <div className="siteLoader"></div> */}
        </div>
    );
}

export default Loader;