const BlackPlusIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" zoomAndPan="magnify" viewBox="0 0 37.5 37.499999" height="20" preserveAspectRatio="xMidYMid meet" version="1.0">
            <defs>
                <clipPath id="a76cb7ad85">
                    <path d="M 0 0 L 37.007812 0 L 37.007812 37.007812 L 0 37.007812 Z M 0 0 " clipRule="nonzero"></path>
                </clipPath>
            </defs>
            <g clipPath="url(#a76cb7ad85)">
                <path fill="#000000" d="M 18.75 0 C 8.4375 0 0 8.4375 0 18.75 C 0 29.0625 8.4375 37.5 18.75 37.5 C 29.0625 37.5 37.5 29.0625 37.5 18.75 C 37.5 8.4375 29.0625 0 18.75 0 Z M 26.25 20.625 L 20.625 20.625 L 20.625 26.25 C 20.625 27.375 19.875 28.125 18.75 28.125 C 17.625 28.125 16.875 27.375 16.875 26.25 L 16.875 20.625 L 11.25 20.625 C 10.125 20.625 9.375 19.875 9.375 18.75 C 9.375 17.625 10.125 16.875 11.25 16.875 L 16.875 16.875 L 16.875 11.25 C 16.875 10.125 17.625 9.375 18.75 9.375 C 19.875 9.375 20.625 10.125 20.625 11.25 L 20.625 16.875 L 26.25 16.875 C 27.375 16.875 28.125 17.625 28.125 18.75 C 28.125 19.875 27.375 20.625 26.25 20.625 Z M 26.25 20.625 " fillOpacity="1" fillRule="nonzero">
                </path>
            </g>
        </svg>
    )
}

export default BlackPlusIcon;