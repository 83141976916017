import React, { useRef, useState, useEffect } from "react";
import ReactTypingEffect from 'react-typing-effect';
import TripSummaryIcon from "../assets/icons/TripSummaryIcon";
import WhoAmINavIcon from "../assets/icons/WhoAmINavIcon";
import LodgingIcon from "../assets/icons/LodgingIcon";
import CulinaryIcon from "../assets/icons/CulinaryIcon";
import AttractionIcon from "../assets/icons/AttractionIcon";
import NightLifeIcon from "../assets/icons/NightLifeIcon";
import ShoppingIcon from "../assets/icons/ShoppingIcon";
import MapIcon from "../assets/icons/MapIcon";
import CalendarIconWithoutModal from "../assets/icons/CalendarIconWithoutModal";
import FAQIcon from "../assets/icons/FAQIcon";
import ReviewsIcon from "../assets/icons/ReviewsIcon";

const TypingEffect = ({ text, speed }) => {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText((prev) => prev + text[index]);
        setIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(timeout);
    }
  }, [index, text, speed]);

  return <span>{displayText}</span>;
};

const Recommendations = ({
  summaryTab,
  whoAmITab,
  lodgingTab,
  culinaryTab,
  attractionTab,
  nightLifeTab,
  shoppingTab,
  mapTab,
  calendarTab,
  faqTab,
  reviewsTab,
}) => {
  const tabs = useRef([
    { name: "summary", ref: summaryTab, icon: <TripSummaryIcon />, label: "תקציר הטיול" },
    { name: "whoAmI", ref: whoAmITab, icon: <WhoAmINavIcon />, label: "מי אני" },
    { name: "lodging", ref: lodgingTab, icon: <LodgingIcon />, label: "לינה" },
    { name: "culinary", ref: culinaryTab, icon: <CulinaryIcon />, label: "קולינריה" },
    { name: "attraction", ref: attractionTab, icon: <AttractionIcon />, label: "אטרקציות" },
    { name: "nightLife", ref: nightLifeTab, icon: <NightLifeIcon />, label: "חיי לילה" },
    { name: "shopping", ref: shoppingTab, icon: <ShoppingIcon />, label: "שופינג" },
    { name: "map", ref: mapTab, icon: <MapIcon />, label: "מפה מפורטת" },
    { name: "calendar", ref: calendarTab, icon: <CalendarIconWithoutModal />, label: 'לו"ז לדוגמא' },
    { name: "faq", ref: faqTab, icon: <FAQIcon />, label: "שאלות ותשובות" },
    { name: "reviews", ref: reviewsTab, icon: <ReviewsIcon />, label: "ביקורות" },
  ]);

  const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
   
    <div className='homeRecommendations ptb-90'> 
      <div className='container'>
        <div className='homeRecommendationsHead text-center mb-4 pb-2 pb-md-0 mb-md-5'>
            <p>אפשר לחפש מידע לטיול הבא שלכם בכל מיני אתרים, לאסוף לינקים, המלצות מחברים ולקוות לטוב, ואפשר..</p>
            {/* <div style={{ direction: "rtl", textAlign: "center" }}>
              <h4><ReactTypingEffect
                text={["חופשות"]} // Hebrew for 'vacations'
                speed={60}
                eraseSpeed={60}
                eraseDelay={1500}
              />
              </h4>
            </div> */}
            {/* <div style={{display: "flex", justifyContent: "center"}}> */}
              {/* <div style={{ direction: 'rtl', textAlign: 'right' }}> */}
                <h4>
                  <ReactTypingEffect
                    text={["VACAYTIONS"]}
                    speed={60}
                    eraseSpeed={60}
                    // typingDelay={100}
                    eraseDelay={1500}
                  />
                </h4>
              {/* </div> */}
            {/* </div> */}
        </div> 
        
        <hr />
        <div className='recommendTab mt-3 mb-3 mb-md-0 py-2 pt-md-0 mt-md-5'>
          <div className="scrollingTabsWrapperInner d-flex align-items-center">      
            {tabs.current.map(({ name, icon, label }) => (
              <div key={name} className={`singleScrollingTab`}>
                <a href={`/listing/long-weekend-in-barcelona/`} className="d-flex justify-content-end align-items-center flex-column">
                  {/* <div className="d-flex justify-content-end align-items-center flex-column"> */}
                    <div className="navIcon">{icon}</div>
                    <p className="mb-0">{label}</p>
                  {/* </div> */}
                </a>
              </div>
            ))}
          </div>
        </div>
        {isMobile && (
          <>
          <hr />
          <small className="text-center d-block"><a href="/listing/long-weekend-in-barcelona/" className="text-black text-decoration-underline">לצפייה בתיק טיול מלא לדוגמה</a></small>
          </>
        )}
        
      </div>
    </div>
  );
};

export default Recommendations;
