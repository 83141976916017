import React, { useState, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';
import axios from 'axios'; // Import axios for API calls

export default function ItemWithoutThumbnailsDemo({ userId, listingData=false }) {
    const [listings, setListings] = useState();

    const fetchListings = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/listing/user`, {
                params: {
                    "user_id": listingData?.author,
                    "listing_id": listingData?.id
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if(response.data.status !== "error"){
                setListings(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if(listingData){
            fetchListings();
        }
    }, [listingData]);

    const itemTemplate = (item) => {
        return <img src={item.src} alt={item.src} style={{ width: '100%', display: 'block' }} />;
    };

    return (
        <div className='commonListingRow'>
            {listings && listings.map((listing) => (
                <div key={listing.id} className="commonListingBox">
                    <Galleria
                        value={listing.gallery.map(image => ({ src: image }))} // Map gallery URLs to Galleria object format
                        numVisible={5}
                        circular
                        style={{ maxWidth: '640px' }}
                        showThumbnails={false}
                        showItemNavigators
                        item={itemTemplate}
                    />
                    <h4>{listing.title}</h4>
                    <div className='commonListingBottom'>
                        <i className="fas fa-map-marker-alt"></i>
                        {listing.cities.join(', ')} {/* Display cities */}
                    </div>
                </div>
            ))}
        </div>
    );
}
