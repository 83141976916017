import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Carousel } from 'primereact/carousel';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

const Blogs = () => {
    const [travelers, setTravelers] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [width, setWidth] = useState(window.innerWidth);
    const [slidesPerGroup, setSlidesPerGroup] = useState(3);
    const sliderRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                    params: { "action": "posts" },
                    headers: { 'Content-Type': 'application/json' },
                });
                setTravelers(response.data.data);
                // var getBreakDown = responsiveOptionsData[width]?.numVisible;
                // console.log("getBreakDown: ", getBreakDown);
                // var numberOfVisible = updateVisible(width);
                // setTotalPages(Math.ceil(response.data.data.length/3));
            } catch (error) {
                console.error("Error fetching travelers data", error);
            }
        };

        fetchData();
    }, []);

    // const responsiveOptions = [
    //     {
    //         breakpoint: '1400px',
    //         numVisible: 3,
    //         numScroll: 3
    //     },
    //     {
    //         breakpoint: '1199px',
    //         numVisible: 3,
    //         numScroll: 3
    //     },
    //     {
    //         breakpoint: '767px',
    //         numVisible: 2,
    //         numScroll: 2
    //     },
    //     {
    //         breakpoint: '575px',
    //         numVisible: 1,
    //         numScroll: 1
    //     }
    // ];

    const responsiveOptions = {
        767: {
            breakpoint: '767px',
            slidesPerView: 3
        },
        250: {
            breakpoint: '250px',
            slidesPerView: 1.3
        }
    };

    const updateVisible = (width) => {
        var numberOfVisible = Math.ceil((travelers.length)/3);
        var slides = 3;
        if(width <= 1400 && width > 1199){
            
            numberOfVisible = Math.ceil((travelers.length) / 3);
            slides = 3;
        }else if(width <= 1199 && width > 767){

            numberOfVisible = Math.ceil((travelers.length) /2);
            slides = 2;
        }else if(width <= 767 && width > 575){
            
            numberOfVisible = Math.ceil((travelers.length) /2);
            slides = 2;
        }else if(width <= 575){

            numberOfVisible = Math.ceil((travelers.length) /1);
            slides = 1;
        }else{
            // console.log("else")
            // numberOfVisible = travelers /3;
        }

        setTotalPages(numberOfVisible);
        setSlidesPerGroup(slides);
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        updateVisible(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, []);

    useEffect(() => {
        if(travelers.length > 0){
            updateVisible(width)
        }
    }, [travelers])

    const itemTemplate = (blog) => {
        return (
            <div className='outerCommonBlogCard'>
                <div key={blog.id} className="commonBlogCard d-flex flex-column">
                    <div className='commonBlogCardImg'>
                        <img src={blog.image} alt={blog.title} className="blog-image" />
                    </div>                    
                    <h3 className="blogTitle"><a href={blog.permalink} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: blog.title}}></a></h3>                                
                    <div className="commonBlogCardMeta d-flex align-items-center justify-content-between">                                
                        <div className="blogCategory">
                            {blog.categories.map((category, index) => (
                                <a
                                    href={category.slug}
                                    dangerouslySetInnerHTML={{__html: category.name}}
                                >
                                </a>
                            ))}
                        </div>
                        <div className="blogDate">{blog.date}</div>
                    </div>
                    <div className="blogExcerpt" dangerouslySetInnerHTML={{__html: blog.excerpt}}></div>
                    <div className='blogUrllink'>
                        <a href={blog.permalink} target="_blank" rel="noopener noreferrer" className="blog-link">המשך לקרוא</a>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className="blogsSliderOuter ptb-90">
            <div className="container sliderSectionHead">
                <div className='d-flex align-items-center mb-5 sliderTitleNav'>
                    <h2 className='mb-0'>הבלוג של VACAYTIONS</h2>
                    <div className='leftNav d-flex align-items-center'>
                        <h6 className='mb-0 slidesCount'>{currentPage + 1}/{totalPages}</h6>
                        <ul className='sliderNavButtons d-flex'>
                                <li>
                                    <button className={`prevBtn m-0 ${sliderRef?.current?.swiper.activeIndex === 0 ? "disabled" : ""}`} onClick={() => sliderRef?.current?.swiper.slidePrev()}><i className="fa-solid fa-angle-right"></i></button>
                                </li>
                                <li>
                                    <button className={`nextBtn m-0 ${sliderRef?.current?.swiper.isEnd ? "disabled" : ""}`} onClick={() => sliderRef?.current?.swiper.slideNext()}><i className="fa-solid fa-angle-left"></i></button>
                                </li>
                            </ul>
                    </div>
                </div>
                <div className="blogsSliderWrap commonHomeSlider">
                    {/* <Carousel 
                        value={travelers} 
                        numVisible={3} 
                        numScroll={3} 
                        showIndicators={false} 
                        responsiveOptions={responsiveOptions} 
                        itemTemplate={itemTemplate} 
                        onPageChange={(e) => setCurrentPage(parseInt(e.page))}
                        page={currentPage}
                    /> */}

                    <Swiper
                        ref={sliderRef}
                        spaceBetween={0}
                        slidesPerView={3}
                        slidesPerGroup={slidesPerGroup}
                        speed={500}
                        pagination={{ clickable: true }}
                        rtl={true}
                        modules={[Navigation, Pagination]}
                        onSlideChange={(slide) => setCurrentPage(Math.ceil(slide.activeIndex/slidesPerGroup))}
                        breakpoints={responsiveOptions}
                    >
                        {travelers.map((blog, index) => (
                            <SwiperSlide key={index}>
                                {itemTemplate(blog)}
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </div>
                <div className='text-center'>
                    <a href={`${process.env.REACT_APP_API_URL}/%d7%91%d7%9c%d7%95%d7%92/`} class="btn btn-primary mt-5">לבלוג המלא</a>
                </div>
            </div>
        </div>
    );
};

export default Blogs;
