import MapColorIcon from "../assets/icons/MapColorIcon";
import ExitIcon from "../assets/icons/ExitIcon";
import InformationIcon from "../assets/icons/InformationIcon";
import SatteliteMapIcon from "../assets/icons/SatteliteMapIcon";

const DetailedMapTabPanel = ({data, tabRef}) => {
    return (
        <div className="tabPanel" ref={tabRef}>
            <div className="aboutTabPanel">

                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        <MapColorIcon />
                    </div>
                    <h3>מפה מפורטת</h3>                    
                </div>
                
                <div className="singleListingPanelSummary tabDescription">
                    <p>תקבלו לינק למפה מ-Google Maps שתוכלו להשתמש בה בחשבון ה-Google Maps שלכם בנייד  עם כל המלונות, מסעדות, אטרקציות, חיי לילה, שופינג ועוד כך שבכל רגע תוכלו לראות מה קרוב אליכם ולבנות מסלול נוח לכל יום</p>
                    <div className="mapIframe mt-3" 
                        dangerouslySetInnerHTML={{
                            __html: data?.acf?.rz_map_iframe_user,
                        }} 
                    />
                    <div className="singleListingPanelTitleIcon d-flex gap-3">
                        <div className="title_icon exitIconBtn">
                            <ExitIcon />
                        </div>
                        <p>השתמשו בלחצן על גבי המפה לסינון ההמלצות-</p>                    
                    </div>                    
                </div>
                <hr />
                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon exitIconBtn">
                        <InformationIcon />
                    </div>
                    <h3>מפת ההמלצות אצלכם בנייד</h3>
                </div>
                <div className="singleListingPanelSummary">
                    <div className="tabDescription">
                        <p className="text-end">לחצו על הלינק כדי לצפות בכל ההמלצות שלי בחשבון ה-Google Maps שלכם כדי שתמצאו בזמן אמת את כל מה שקרוב אליכם ושווה ביקור תוך כדי שאתם מטיילים</p>
                    </div>
                    <div className="satteliteIconOuter d-flex justify-content-start mt-4">
                        <div className="satteliteIcon text-center">
                            <a href={data?.acf?.rz_map_link_another_user} className="btn btn-primary d-inline-flex gap-2 align-items-center">
                                <SatteliteMapIcon />
                                חיבור ל-Google Maps                                
                            </a>
                            <div className="how_it_work font-12 mt-2">
                                <a href="#" >איך זה עובד?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailedMapTabPanel;