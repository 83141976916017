import FAQIcon from "../assets/icons/FAQIcon";
import { Accordion, AccordionTab } from 'primereact/accordion';

const FAQTabPanel = ({data, tabRef}) => {
    return (
        <div className="tabPanel" ref={tabRef}>
            <div className="aboutTabPanel">

                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        <FAQIcon />
                    </div>
                    <h3>שאלות נפוצות</h3>
                </div>

                <div className="singleListingPanelSummary">
                    <div className="faqQuestions">
                        <Accordion>
                            {data && data.map((item, key) => (
                                item.rz_user_faq_a.length > 0 && (
                                    <AccordionTab header={item.rz_user_faq_q}>
                                        <div
                                            className="tabDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: item.rz_user_faq_a
                                            }}
                                            />
                                    </AccordionTab>
                                )
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQTabPanel;