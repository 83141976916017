import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listingData: {},
    calendarDate: String(new Date())
}

const listingSlice = createSlice({
    name: "listingData",
    initialState,
    reducers: {
        updateListingData(state, action){
            state.listingData = { 
                ...state.listingData, 
                ...action.payload 
            };
        },
        updateCalendarDate(state, action){
            state.calendarDate = String(action.payload);
        }
    }
});

export const { updateListingData, updateCalendarDate } = listingSlice.actions;
export default listingSlice.reducer;