const CircleMapIcon = () => {
    return (        
        <svg version="1.0" viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M161 483 c-88 -31 -161 -136 -161 -233 0 -132 118 -250 250 -250 60
            0 130 30 175 75 188 188 -12 497 -264 408z m134 -87 c90 -38 90 -157 0 -265
            -34 -39 -56 -39 -90 0 -89 107 -90 226 -2 264 41 18 50 18 92 1z"></path>
            <path d="M207 332 c-22 -24 -21 -65 1 -85 40 -36 102 -9 102 45 0 51 -69 78
            -103 40z m67 -5 c12 -9 17 -23 14 -42 -3 -27 -7 -30 -38 -30 -31 0 -35 3 -38
            30 -3 26 17 55 38 55 3 0 14 -6 24 -13z"></path>
            </g>
        </svg>
    )
}
export default CircleMapIcon;
