import axios from 'axios';
import React from 'react';
import editIcon from "../assets/images/edit-icon.png";
import deleteEventIcon from "../assets/images/deleteEventIcon.png";
import EyeIcon from '../assets/icons/EyeIcon';
import NotificationIcon from '../assets/icons/NotificationIcon';
import LocationIcon from '../assets/icons/LocationIcon';

const CalendarModal = ({ isOpen, onClose, selectedEvent, openEditModal, getCalendarData }) => {
    if (!isOpen) return null;

    if (!selectedEvent) {
        return null;
    }


    const startTimeObj = new Date(selectedEvent.start);
    const endTimeObj = new Date(selectedEvent.end);

    const startTime = `${startTimeObj.getMinutes() < 10 ? `0${startTimeObj.getMinutes()}` : startTimeObj.getMinutes()} : ${startTimeObj.getHours()}`
    const endTime = `${endTimeObj.getMinutes() < 10 ? `0${endTimeObj.getMinutes()}` : endTimeObj.getMinutes()} : ${endTimeObj.getHours()}`

    const handleEditEventClick = () => {
        onClose();
        openEditModal(true);
    }

    const handleDeleteEventClick = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/wp-json/calendar-diary-api/delete/${selectedEvent.id}`);
            if (response) {
                getCalendarData();
                onClose();
            }
        } catch (err) {
            console.log("error: ", err.response);
        }
    }

    const handleScrolling = (key) => {
        const element = document.querySelector(`.singleRepeater.${key}`);
        const yOffset = -300;
        const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
    }

    return (
        <div className="modal-overlay calendarActionModal" onClick={onClose}>
            <div className="modal-contents" onClick={e => e.stopPropagation()}>
                <div className='modal_header'>
                    <span className="close" onClick={onClose}>&times;</span>
                    <img src={deleteEventIcon} alt='delete icon' onClick={handleDeleteEventClick} />
                    <img src={editIcon} alt='edit icon' onClick={handleEditEventClick} />
                    {(selectedEvent?.acc_id && selectedEvent?.acc_id !== "" && selectedEvent?.acc_id !== undefined) && (
                        <div className={`scrollEyeIcon`} onClick={() => handleScrolling(selectedEvent?.acc_id)} >
                            <EyeIcon />
                        </div>
                    )}
                </div>
                <div className='modal_body'>
                    <div className='eventDetails'>

                        <div className='eventTitle'>
                            {selectedEvent && (
                                selectedEvent.acc_title
                            )}
                        </div>

                        <div className='eventDate'>
                            {selectedEvent && (
                                selectedEvent.calendar_date
                            )}
                        </div>

                        <div className='eventTiming'>
                            {selectedEvent && (<>
                                <div>{startTime}</div>
                                <div>{endTime}</div>
                            </>)}
                        </div>
                    </div>

                    <div className='eventTiming d-flex eventDetailsIconText'>
                        {selectedEvent && (<>
                            <NotificationIcon />
                            <div>תזכורת - {selectedEvent.calendar_reminder} שעה</div>                                
                        </>)}
                    </div>

                    <div className='eventLocation d-flex eventDetailsIconText'>
                        {selectedEvent && (
                            <>  
                               <LocationIcon /> 
                                <a href={selectedEvent.location_url} target="_blank" rel="noopener noreferrer">
                                    מיקום
                                </a>                                    
                            </>
                        )}
                    </div>

                    {/* Conditional buttons */}
                    <div className='eventDetailsButtons d-flex'>
                    {selectedEvent.website_link && (
                        <a href={selectedEvent.website_link} target="_blank" rel="noopener noreferrer" className="button">
                            לאתר
                        </a>
                    )}

                    {selectedEvent.reservation_link && (
                        <a href={selectedEvent.reservation_link} target="_blank" rel="noopener noreferrer" className="button">
                            להזמנה
                        </a>
                    )}
                    </div>                    
                </div>
            </div>
        </div>
    );
};

export default CalendarModal;
