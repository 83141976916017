import React, { useState, useEffect } from 'react';
import axios from "axios";
import Layout from './Layout';
import HomeBanner from '../components/HomeBanner';
import RecommendationsTab from '../components/RecommendationsTab';
import MeetVacaytions from '../components/MeetVacaytions';
import LovedDestinations from '../components/LovedDestinations';
import HomeHowWork from '../components/HomeHowWork';
import RecomendedVacations from '../components/RecomendedVacations';
import Travelers from '../components/Travelers';
import TextWithButton from '../components/TextWithButton';
import Faq from '../components/Faq';

import Blogs from '../components/VacationsBlogs';
import Loader from '../components/Loader';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// const currentEnv = process.env.NODE_ENV;
// var userId = 0;

// if (currentEnv === "production") { 
//   console.log("production");
//   userId = localStorage.getItem("vacaytion_user_id");
// } else {
//   userId = 0;
// }


const HomePage = () => {
    const [isLoading, setIsLoading] = useState(false);
        const [userId, setCheckLoginUserId] = useState(0);

    const homeMetaData = {
        title: "Homepage - vacaytions",
        description: "תכנון לפי יעד תכנון לפי מפרסם איפה מטיילים? איפה מטיילים? איפה מטיילים? מדינה - אוזבקיסטן אוסטרליה אוקראינה אורוגוואי אזרבייג'אן איחוד האמירויות איטליה איי סיישל אירלנד אלבניה אנגליה אנדורה אקוודור ארגנטינה ארובה ארמניה ארצות הברית בולגריה בוליביה בוסניה והרצגובינה בלגיה ברבדוס ברזיל ג'מייקה גאורגיה גואטמלה גרמניה דנמרק דרום אפריקה דרום קוריאה האיים המלדיביים הודו הולנד הונג"
    } 
  
  
    const CheckLoginUserId = async () => {
        //setIsLoading(true); // Start loading
        try {  
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/wp-json/user/check-login`
            );
            if (response.data.data.status === 200) {
                setCheckLoginUserId(response.data.data.user_id);
            }else{
                setCheckLoginUserId(0);
            }   
        } catch (err) {
            setCheckLoginUserId(0);
 
        }     
        // setIsLoading(false); // Stop loading
    };    
  
    useEffect(() => {
        CheckLoginUserId(); // Call the login check function
    }, []);


    return ( <>
        <Layout userId={userId} showBlur={false} metaData={homeMetaData}>  
            <div className='homepageContent'>
                <HomeBanner />
                <RecommendationsTab />
                <MeetVacaytions />
                <LovedDestinations />
                <HomeHowWork />            
                <RecomendedVacations showLoader={setIsLoading}/>
                <Travelers showLoader={setIsLoading}/>

                <div className='container'>
                    <hr className='greyDevider' />
                </div>

                <TextWithButton />
                <div className='container'>
                    <hr className='greyDevider m-0' />
                </div>            
                <Faq />
                <Blogs/>
            </div>
            </Layout>

            {isLoading && (
                <Loader />
            )}
        </>
    );
};

export default HomePage;
