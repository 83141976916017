import { Link } from 'react-router-dom';


const TextWithButton = () => { 
    return (
        <div className='textWithButton ptb-90'>
            <div className='container text-center'>
                <h2 className='mb-4 text-center'>טרוולרז או מומחי טיולים בעולם?</h2>
                <p className='mb-4 text-center'>הצטרפו אלינו למסע ליצירת מסלולי טיול עם כל ההמלצות לכל יעד בעולם עבור קהל המטיילים</p>                
                <Link to='' className='orangeRadiusButton mb-2'>צור מסלול חדש</Link> 
                <p><small><Link to='' className='text-black text-decoration-underline'>איך זה עובד?</Link></small></p>
            </div>
        </div>
    );
};

export default TextWithButton;
