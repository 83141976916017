import React, { useEffect, useState, useRef } from 'react';
import ReviewsIcon from "../assets/icons/ReviewsIcon";
import axios from 'axios';
import Rating from 'react-rating-stars-component'; // Import the rating component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageGallery from './ImageGallery';

const ReviewsTab = ({ data, tabRef, userId, updateReviewCount }) => {
    const [reviewCount, setReviewCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState("");
    const [serviceRating, setServiceRating] = useState(0);
    const [moneyValueRating, setMoneyValueRating] = useState(0);
    const [recommendRating, setRecommendRating] = useState(0);
    const [matchesExpectationsRating, setMatchesExpectationsRating] = useState(0);
    const [reliabilityRating, setReliabilityRating] = useState(0);
    const [photos, setPhotos] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [hoverRating, setHoverRating] = useState(null);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const reviewImgRef = useRef();
    const [ratingData, setRatingData] = useState([]);
    const [comments, setComments] = useState([]);
    const [averageRating, setAverageRating] = useState(0);
    const [userAvatar, setUserAvatar] = useState("");

    let listingId = data.id;

    const fetchReviewCount = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/review/list`, {
                params: {
                    listing_id: listingId
                },
                headers: {
                    'Content-Type': 'application/json',
                },

            });

            setReviewCount(response.data.comments.length || 0);
            setRatingData(response.data.rating_data || []);
            setComments(response.data.comments || []);
            setAverageRating(parseFloat(response.data.main_rating) || 0);
            updateReviewCount(parseFloat(response.data.main_rating) || 0);
            setUserAvatar(response.data.comments[0].user_avatar || "");
            // setMainRating()
            var imageUrls = [];

            Promise.all(
                response.data.comments.map((comment) => {
                    var urls = []
                    Promise.all(comment.rz_gallery.map(async (galleryItem) => {
                        try {
                            const res = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/media/${galleryItem.id}`);
                            const url = res.data.media_details.sizes.rz_thumbnail.source_url;
                            urls.push(url);

                        } catch (err) {
                            console.log("Error getting the image: ", err);
                            return null;
                        }
                    }))

                    imageUrls[comment?.comment_ID] = urls;
                })
            );

            setGallery(imageUrls);

        } catch (error) {
            console.error("Error fetching review count:", error);
        }
    };

    useEffect(() => {
        fetchReviewCount();
    }, []);

    const submitReview = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleRatingChange = (setter) => (newRating) => {
        setter(newRating);
    };
    const handleMouseEnter = (setter) => (rating) => {
        setHoverRating(rating);
    };

    const handleMouseLeave = () => {
        setHoverRating(null);
    };




    const handleMatchesExpectationsChange = (newRating) => {
        setMatchesExpectationsRating(newRating);
    };

    const handleReviewTextChange = (event) => {
        setReviewText(event.target.value);
    };

    const handlePhotoChange = async (event) => {
        const files = Array.from(event.target.files);
        setIsImageLoading(true);
        setPhotos(files);

        if (files.length > 0) {
            const formData = new FormData();
            files.forEach((photo) => {
                formData.append('rz_file_upload[]', photo);
            });

            try {
                const uploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/wp-json/upload/add`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setIsImageLoading(false);

                const uploadedPhotoIds = uploadResponse.data.map(photo => photo.id);
                const gallery = uploadedPhotoIds.map(id => ({ id }));

                setGallery(gallery);

            } catch (error) {
                setIsImageLoading(false);
                console.error('Error uploading photos:', error);
                toast.error(`Error uploading photos: ${error.message}`);
            }
        }
    };

    let listing_id = data.id;
    // let user_id = 2726;
    let user_id = userId;
    const handleSubmit = async () => {

        const formData = new FormData();
        photos.forEach((photo) => {
            formData.append('rz_file_upload[]', photo);
        });

        try {

            const reviewData = {
                listing_id: listing_id,
                user_id: user_id,
                comment: reviewText,
                gallery: gallery,
                review: {
                    ratings: {
                        "cleanliness": serviceRating,
                        "communication": moneyValueRating,
                        "location": reliabilityRating,
                        "value": matchesExpectationsRating,
                        "check-in": recommendRating,
                    },
                },
            };

            const data = await axios.post(`${process.env.REACT_APP_API_URL}/wp-json/review/add`, reviewData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (data.data.message == "You have already submitted a review for this listing.") {
                toast.error(`You have already submitted a review for this listing.`);
            } else {
                toast.success(data.data.message);

            }
            setReliabilityRating(0);
            setMatchesExpectationsRating(0);
            setRecommendRating(0);
            setMoneyValueRating(0);
            setServiceRating(0);
            setPhotos([]);
            if (reviewImgRef.current) {
                reviewImgRef.current.value = "";
            }

        } catch (error) {
            console.error('Error uploading photos:', error);
            toast.error(`You have already submitted a review for this listing.`);
        }

        closeModal();
    };

    return (
        <div className="tabPanel" ref={tabRef}>
            <ToastContainer />

            <div className="aboutTabPanel">

                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="panelIcon">
                        <ReviewsIcon />
                    </div>
                    <h3 className="text-end">חוות דעת</h3>
                </div>
                <div className="singleListingPanelSummary">
                    <div className='d-flex justify-content-between align-items-center gap-2'>
                        <div className="d-flex justify-content-end align-items-center gap-2">
                            <h6 className="m-0">
                                {reviewCount === 0 && averageRating === 0
                                    ? "0 חוות דעת"
                                    : `${reviewCount} ${averageRating} חוות דעת`}
                            </h6>
                        </div>
                        <button onClick={submitReview} className="orangeButton m-0">שלח חוות דעת</button>
                    </div>

                    <div className='reviewsContent'>
                        {ratingData.length > 0 && (
                            <div className="ratingProgressBars">
                                {ratingData.map((ratingItem, index) => (
                                    <div key={index} className="rating-item">
                                        <div className="rating-number">{ratingItem.rating_number}</div>

                                        <span>{ratingItem.name}</span>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: `${ratingItem.rating_average}%` }}
                                                aria-valuenow={ratingItem.rating_average}
                                                aria-valuemin="0"
                                                aria-valuemax="100">
                                                {ratingItem.rating_average}%
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* Comments Section */}
                        {comments.map((comment, index) => (
                            <div key={index} className="comment">
                                <div className="comment-header d-flex">
                                    {/* <img src={userAvatar} alt="User Avatar" className="user-avatar" /> */}
                                    <div className='userAvtarIcon'>
                                        <i class="fa-solid fa-user"></i>
                                    </div>
                                    <div className="comment-header-content">
                                        <div className="comment-author">
                                            <span className='star_rating d-inline-flex align-items-center'>
                                                <strong>{comment.rating}</strong>
                                                <i class="fas fa-star mx-1"></i>                                                
                                            </span>
                                            <span>{comment.comment_author}</span>
                                        </div>
                                        <div className="comment-date">{new Date(comment.comment_date).toLocaleDateString()}</div>
                                    </div>
                                </div>
                                <div className="comment-content">{comment.comment_content}</div>

                                <div className="comment-gallery">
                                    {gallery.length > 0 && (
                                        // <img
                                        //     key={key}
                                        //     src={item}
                                        //     alt={`Gallery Image ${key}`}
                                        //     className="gallery-image"
                                        //     onClick={() => openLightbox(key)} style={{ backgroundImage: `url(${item})` }}
                                        // />

                                        <ImageGallery
                                            images={gallery[comment?.comment_ID]}
                                            title={false}
                                            isReview={true}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className={`modal ${isModalOpen ? "lightModal ratingModal" : ""}`}>
                    <div className="modal-content">
                        <div className='modal_header'>
                            <h4 className='text-center'>הוסף חוות דעת</h4>
                            <span className="close" onClick={closeModal}><i class="fas fa-times"></i></span>
                        </div>
                        <div className='modal_body'>
                            <h4>דרגו את החוויה</h4>
                            <div className="rating-container">
                                <div className="rating-stars">
                                    <span className="rating-label">תמורה למחיר</span>
                                    <Rating
                                        count={5}
                                        value={moneyValueRating}
                                        onChange={handleRatingChange(setMoneyValueRating)}
                                        size={16}
                                        color="#ddd"
                                        activeColor="#000"
                                        isHalf={false}
                                        onMouseEnter={handleMouseEnter(setMoneyValueRating)}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </div>
                                <div className="rating-stars">
                                    <span className="rating-label">רמת שירות או מוצר</span>
                                    <Rating
                                        count={5}
                                        value={serviceRating}
                                        onChange={handleRatingChange(setServiceRating)}
                                        size={16}
                                        color="#ddd"
                                        activeColor="#000"
                                        isHalf={false}
                                        onMouseEnter={handleMouseEnter(setServiceRating)}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </div>
                                {/* <div className="rating-stars">
                                    <Rating
                                        count={5}
                                        onChange={handleMatchesExpectationsChange}
                                        size={25} 
                                        activeColor="#ffd700"
                                    />
                                    <span className="rating-label">Matches expectations

                                    </span>
                                </div> */}
                            </div>
                            <div className="rating-container">
                                <div className="rating-stars">
                                    <span className="rating-label">מהימנות המידע</span>
                                    <Rating
                                        count={5}
                                        value={reliabilityRating}
                                        onChange={handleRatingChange(setReliabilityRating)}
                                        size={16}
                                        color="#ddd"
                                        activeColor="#000"
                                        isHalf={false}
                                        onMouseEnter={handleMouseEnter(setRecommendRating)}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </div>
                                <div className="rating-stars">
                                    <span className="rating-label">ממליץ לאחרים</span>
                                    <Rating
                                        count={5}
                                        value={recommendRating}
                                        onChange={handleRatingChange(setRecommendRating)}
                                        size={16}
                                        color="#ddd"
                                        activeColor="#000"
                                        isHalf={false}
                                        onMouseEnter={handleMouseEnter(setRecommendRating)}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </div>
                            </div>
                            <div className="rating-container">
                                <div className="rating-stars">
                                    <span className="rating-label">תואם את הציפיות</span>
                                    <Rating
                                        count={5}
                                        value={matchesExpectationsRating}
                                        onChange={handleRatingChange(setMatchesExpectationsRating)}
                                        size={16}
                                        color="#ddd"
                                        activeColor="#000"
                                        isHalf={false}
                                        onMouseEnter={handleMouseEnter(setMatchesExpectationsRating)}
                                        onMouseLeave={handleMouseLeave}

                                    />
                                </div>
                            </div>

                            <h4 className='mt-3' style={{ marginTop: '20px' }}>תרצו להוסיף משהו?
                            </h4>
                            <textarea
                                className="review-textarea"
                                placeholder="Write your review here..."
                                value={reviewText}
                                onChange={handleReviewTextChange}
                            />

                            <div className="photo-upload">
                                <h4 style={{ marginTop: '20px' }}>הוסף תמונות
                                </h4>
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handlePhotoChange}
                                    style={{ marginTop: '10px' }}
                                    ref={reviewImgRef}
                                    className={isImageLoading ? "disabled" : ""}
                                />
                                <div className="photo-preview" style={{ marginTop: '10px' }}>
                                    {photos.length > 0 && (
                                        <div>
                                            {/* <h4>Selected Photos:</h4> */}
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                {photos.map((photo, index) => (
                                                    <img
                                                        key={index}
                                                        src={URL.createObjectURL(photo)}
                                                        alt={`preview-${index}`}
                                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                        className={isImageLoading ? "disabled" : ""}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='modal_footer'>
                            <div className="modal-actions submit-button-container w-100 mt-0">
                                <button
                                    onClick={handleSubmit}
                                    className="orangeButton"
                                    disabled={isImageLoading ? true : false}
                                >שלח</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReviewsTab;