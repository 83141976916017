import { useEffect, useState } from "react";
import NightLifeIcon from "../assets/icons/NightLifeIcon";
import axios from "axios";

const NightLifeTabPanel = ({ data,tabRef }) => {
    const [accommodationData, setAccommodationData] = useState([]);
    const handleScroll = () => {
        if (tabRef.current) {
            const elementPosition = tabRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - 250;
        
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
        }
    }
    useEffect(() => {
        const fetchAccommodationData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/recommendation_counts/show`, {
                    params: {
                        listing_id: data.id,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.data.status === 'success') {
                    setAccommodationData(response.data.data.rz_night || []);
                }
            } catch (error) {
                console.error('Error fetching accommodation data:', error);
            }
        };
        fetchAccommodationData();
    }, []);

    return (
        <div className="tabPanel">
            <div className="aboutTabPanel">

                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        <NightLifeIcon />
                    </div>
                    <h3>חיי לילה</h3>
                </div>

                <div
                    className="singleListingPanelSummary tabDescription"
                    dangerouslySetInnerHTML={{
                        __html: data?.acf?.rz_night_about_places,
                    }}
                />

                {accommodationData.length > 0 && (
                    <div className="recommendationData">
                        <ul>
                            {accommodationData.map((item, index) => (
                                <li key={index}>
                                    <strong onClick={handleScroll}>{item.name}</strong>: {item.count}
                                    </li>
                            ))}
                        </ul>
                    </div>
                )}

            </div>
        </div>
    );
}

export default NightLifeTabPanel;