import axios from 'axios';
import React, { useEffect, useState } from 'react';

const CommonModal = ({children, onClose}) => {
    return (
        <div className="modal-overlay profileInfoModal" onClick={onClose}>
            <div className="modal-contents" onClick={e => e.stopPropagation()}>
                <div className='modal_header'>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className='modal_body'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CommonModal;
