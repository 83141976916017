import React, { useEffect, useRef, useState } from "react";
import TripSummaryIcon from "../assets/icons/TripSummaryIcon";
import WhoAmINavIcon from "../assets/icons/WhoAmINavIcon";
import LodgingIcon from "../assets/icons/LodgingIcon";
import CulinaryIcon from "../assets/icons/CulinaryIcon";
import AttractionIcon from "../assets/icons/AttractionIcon";
import NightLifeIcon from "../assets/icons/NightLifeIcon";
import ShoppingIcon from "../assets/icons/ShoppingIcon";
import MapIcon from "../assets/icons/MapIcon";
import CalendarIconWithoutModal from "../assets/icons/CalendarIconWithoutModal";
import FAQIcon from "../assets/icons/FAQIcon";
import ReviewsIcon from "../assets/icons/ReviewsIcon";
import { isMobile } from 'react-device-detect';

const ScrollingTabs = ({
  summaryTab,
  whoAmITab,
  lodgingTab,
  culinaryTab,
  attractionTab,
  nightLifeTab,
  shoppingTab,
  mapTab,
  calendarTab,
  faqTab,
  reviewsTab,
  userId,
  listingData,
  showBlur
}) => {
  const [currentActiveTab, setCurrentActiveTab] = useState("summary");

  const tabs = [
    { name: "summary", ref: summaryTab, icon: <TripSummaryIcon />, label: "תקציר הטיול" },
    { name: "whoAmI", ref: whoAmITab, icon: <WhoAmINavIcon />, label: "מי אני" },
    { name: "lodging", ref: lodgingTab, icon: <LodgingIcon />, label: "לינה" },
    { name: "culinary", ref: culinaryTab, icon: <CulinaryIcon />, label: "קולינריה" },
    { name: "attraction", ref: attractionTab, icon: <AttractionIcon />, label: "אטרקציות" },
    { name: "nightLife", ref: nightLifeTab, icon: <NightLifeIcon />, label: "חיי לילה" },
    { name: "shopping", ref: shoppingTab, icon: <ShoppingIcon />, label: "שופינג" },
    { name: "map", ref: mapTab, icon: <MapIcon />, label: "מפה מפורטת" },
    { name: "calendar", ref: calendarTab, icon: <CalendarIconWithoutModal />, label: 'לו"ז לדוגמא' },
    { name: "faq", ref: faqTab, icon: <FAQIcon />, label: "שאלות ותשובות" },
    { name: "reviews", ref: reviewsTab, icon: <ReviewsIcon />, label: "ביקורות" },
  ];

  const scrollToTab = (tabRef, tabName) => {
    // tabRef.current?.scrollIntoView({ behavior: "smooth" });
    if (tabRef.current) {
      const elementPosition = tabRef.current.getBoundingClientRect().top;
      let spaceFromTop = 210;

      if(isMobile){
        spaceFromTop = 150;
      }
      const offsetPosition = elementPosition + window.scrollY - spaceFromTop;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      // setCurrentActiveTab(tabName);
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const activeTab = tabs.find((tab) => tab.ref.current === entry.target);
          if (activeTab) {
            setCurrentActiveTab(activeTab.name);
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    tabs.forEach(({ ref }) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      tabs.forEach(({ ref }) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [tabs]);

  return (
    <div className="scrollingTabsWrapper">
      <div className="container d-flex align-items-start scrollingTabsContainer">
        <div className="scrollingTabsWrapperInner  d-flex align-items-center">      
          {tabs.map(({ name, ref, icon, label }) => (
            <div key={name} className={`singleScrollingTab ${currentActiveTab === name ? 'active' : ''}`} onClick={() => scrollToTab(ref, name)}>
              <div className={`d-flex justify-content-end align-items-center flex-column`}>
                <div className="navIcon">{icon}</div>
                <p className="mb-0">{label}</p>
              </div>
            </div>
          ))}
        </div>

        {userId == 0 ? (
          <a href={`${process.env.REACT_APP_API_URL}/login/?listing_id=${listingData.id}`} className="headerLoginBtn">
              לרכישה
          </a>
        ) : (
          showBlur && (
              <a href={`${process.env.REACT_APP_API_URL}/add-to-cart.php?listing_id=${listingData.id}`} className="headerLoginBtn">
                  לרכישה
              </a>
          )
        )}
      </div>
    </div>
  );
};

export default ScrollingTabs;
