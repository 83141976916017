import { useState, useEffect } from "react";
const InstagramVideo = ({ url }) => {
    const [permalink, setPermalink] = useState('');

    const mystyle = {
        background: "white",
        width: "calc(100% - 2px)",
        border: "1px solid rgb(219, 219, 219)",
        boxShadow: "none",
        display: "block",
        padding: "0px",
    };

    const getIframeUrl = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        
        const blockquoteElement = doc.querySelector('blockquote.instagram-media');
        if (blockquoteElement) {
            const permalinkValue = blockquoteElement.getAttribute('data-instgrm-permalink');
            if(permalinkValue){
                let formatted = permalinkValue.split("/?");
                setPermalink(`${formatted[0]}/embed/captioned/`);
            }
        }
    }
    
    useEffect(() => {
        if(url !== ""){
            getIframeUrl(url);
        }
    }, [url]);
    

    return (
        permalink && (
            <div className="instagramIframe">
                <iframe className="instagram-media instagram-media-rendered" id="instagram-embed-1" src={permalink} style={mystyle} allowtransparency="true" allowfullscreen="true" frameborder="0" height="887" data-instgrm-payload-id="instagram-media-payload-1" scrolling="no" title="instagram" data-uw-rm-iframe="gn"></iframe>
                </div>
        )
    );
};

export default InstagramVideo;
