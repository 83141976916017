import ShoppingIcon from "../../assets/icons/ShoppingIcon";
import SubShoppingIcon from "../../assets/icons/SubShoppingIcon";
import { getShoppingFeatureIcons } from "../../utils/getIcon";
import getFeatureName from "../../utils/getFeatureName";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import ImageGallery from "../ImageGallery";
import { useSelector } from "react-redux";

const ShoppingRepeater = ({ data, tabRef, updateHover, openCalendar, setIsCalendarLoading, setMainLoader }) => {
    const { listingData } = useSelector((state) => state.listingData);

    return (
        <div className="tabPanel" ref={tabRef}>
            <div className="aboutTabPanel">
                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        <ShoppingIcon />
                    </div>
                    <h3>שופינג ושווקים</h3>                                        
                </div>
                {/* <CalendarIcon height={40} width={40} /> */}

                <div className="repeaterFields">
                    {data && (
                        data.map((item, key) => (
                            <div className={`singleRepeater rz_shopping${key} mb-5`} key={`nightlife-${key}`}
                                onMouseEnter={() => updateHover({ rzType: "rz_shopping", placeId: item?.rz_shopping_full_address?.place_id })}
                                onMouseLeave={() => updateHover({})}
                            >
                                <div className="singleListingPanelTitleIcon d-flex">
                                    <div className="title_icon subpanelIcon">
                                        <SubShoppingIcon />
                                    </div>

                                    <div className="singleListingPanelSummary">
                                        <div className="reapterTitle">
                                            <h5>{item.rz_shopping_name}</h5>
                                            {listingData?.id !== 17147 && (
                                                <CalendarIcon name={item.rz_shopping_name} height={40} width={40} accId={"rz_shopping"} index={key} itemData={item} openCalendar={openCalendar} setIsCalendarLoading={setIsCalendarLoading} setMainLoader={setMainLoader} />
                                            )}
                                        </div>
                                        <div className="repeaterAddress">
                                            {item?.rz_shopping_full_address?.address} - <a href={
                                                    `https://www.google.com/maps/search/?api=1&query=${item?.rz_shopping_full_address?.address}&query_place_id=${item?.rz_shopping_full_address?.place_id}`
                                                }>צפיה במפה</a>
                                        </div>
                                        {item?.shopping_gallery && (
                                            <div className="repeaterGalleryData">
                                                <ImageGallery
                                                    images={item?.shopping_gallery}
                                                    showBadge={false}
                                                />
                                            </div>
                                        )}
                                        <div className="repeaterHighLightFeatures d-flex">
                                            {item?.rz_shop_features_highlight && (
                                                item?.rz_shop_features_highlight.map((hightLight, hightLightKey) => (
                                                    <div className="listingTabFeatureItem" key={hightLightKey}>
                                                        <div className="highLightFeature d-flex flex-column align-items-center gap-1">
                                                            <div className="featureIcons"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: getShoppingFeatureIcons(hightLight) && getShoppingFeatureIcons(hightLight)[hightLight]
                                                                }}
                                                            />

                                                            <p>{getFeatureName(hightLight, 'shopping')} </p>
                                                        </div>                                                
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                        <div className="reapterDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: item.rz_shopping_description
                                            }}
                                        />

                                        <div className="listingTabActionButtons d-flex">
                                            {item.rz_shopping_reservaction_link !== "" && (
                                                <div className="reservactionLink">
                                                    <a href={item.rz_shopping_reservaction_link} className="btn btn-info">להזמנה</a>
                                                </div>
                                            )}

                                            {item.rz_shopping_website_link !== "" && (
                                                <div className="reservactionLink">
                                                    <a href={item.rz_shopping_website_link} className="btn btn-dark">לאתר</a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        ))
                    )}
                </div>
            </div>
            <hr />
        </div>
    );
}

export default ShoppingRepeater;