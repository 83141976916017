import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        maxWidth: '500px',
        maxHeight: '810px',
        margin: 'auto',
        padding: '0px',
    },
    overlay: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
    }
};

const ContactModal = ({ isOpen, onClose, data, userId }) => {
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [conversationId, setConversationId] = useState();
    const [userInfo, setUserInfo] = useState(null); // State to hold user info

    const handleSend = async () => {
        const reviewData = {
            listing_id: data.id,
            sender_id: userId,
            text: message,
        };

        if (conversationId) {
            reviewData.conversation_id = conversationId;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/wp-json/chat/add`, reviewData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setConversationId(response?.data?.data?.conversation_id);

            setMessages([...messages, { text: message, sender_id: userId, created_at: new Date().toISOString() }]);

            setMessage("");

            // onClose();
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    useEffect(() => {
        if (isOpen && conversationId) {
            const fetchMessages = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/chat/show`, {
                        params: { conversation_id: conversationId },
                        headers: { 'Content-Type': 'application/json' },
                    });

                    if (response.status === 200 && Array.isArray(response.data)) {
                        setMessages(response.data);
                    }
                } catch (error) {
                    console.error("Error fetching messages:", error);
                }
            };

            fetchMessages();
        }
    }, [isOpen, conversationId]);


    const fetchConversationId = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/chat/show/user`, { params: { user_id: userId }, }, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.status === 200 && response.data?.conversation_id) {
                setConversationId(response.data.conversation_id); // Set the conversation ID for future use
            }
        } catch (error) {
            console.error("Error fetching conversation ID:", error);
        }
    };



    useEffect(() => {
        if (isOpen) {
            const fetchUserInfo = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/user-info/show/${data?.author}`);
                    if (response.status === 200) {
                        setUserInfo(response.data); // Set fetched user info
                    }
                } catch (error) {
                    console.error("Error fetching user info:", error);
                }
            };

            fetchUserInfo();
            fetchConversationId(); // Fetch conversation ID when modal opens
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="lightModal"
            style={customStyles}
        >
            <div className='modal_header position-relative'>
                <h4 className='text-center m-0'>שלח הודעה</h4>
                <span className="close" onClick={onClose}><i className="fas fa-times"></i></span>
            </div>


            {userInfo && (
                <div className='modal_image_text d-flex align-items-center'>
                    <img src={userInfo.user_avatar} alt={userInfo.user_nicename} />
                    <h6 className='m-0' dangerouslySetInnerHTML={{ __html: data.title.rendered }} />
                </div>
            )}
            <div className='conversationTab'>{userInfo ? userInfo.display_name : ''}</div>
            
            <div className='modal_body styleScroll'>
                <div className='messagesBox'>
                    {Array.isArray(messages) && messages.length > 0 ? (
                        messages.map((msgObj, index) => (<>
                            <div className='messageDateDiv '>
                                <div className='messageDate'>
                                    {index === 0 && new Date(msgObj.created_at).toLocaleDateString()}{" "}
                                </div>
                            </div>
                            <div key={index}>
                                {msgObj.sender_id === String(userId) ? (
                                    <div className='messageMe commonChatbubble d-flex'>
                                        <div className='messageMeInner justify-content-end d-flex'>
                                            <div className='messageMeImg commonMessageUserImg'>
                                                <img src={userInfo ? userInfo.user_avatar : "default-image-url"} alt="User Image" />
                                            </div>
                                            <div className='messageMeContent position-relative'>
                                                <span className='messageTime'>
                                                    {/* {new Date(msgObj.created_at).toLocaleDateString()}{" "} */}
                                                    {new Date(msgObj.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                </span>
                                                <div className='myMessageText'>
                                                    {msgObj.text}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='messageNotMe commonChatbubble d-flex'>
                                        <div className='messageNotMeInner d-flex flex-row-reverse justify-content-start w-100'>
                                            <div className='messageNotMeImg commonMessageUserImg'>
                                                <img src={userInfo ? userInfo.user_avatar : "default-image-url"} alt="User Image" />
                                            </div>
                                            <div className='messageNotMeContent position-relative'>
                                                <span className='messageTime'>
                                                    {/* {new Date(msgObj.created_at).toLocaleDateString()}{" "} */}
                                                    {new Date(msgObj.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                </span>
                                                <div className='otherMessageText'>
                                                    {msgObj.text}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            </>
                        ))
                    ) : (
                        <p>אין הודעות זמינות</p>
                    )}
                </div>
            </div>

            <div className='modal_footer'>
                <div className='chatMessageInput position-relative'>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="רשמו את ההודעה פה"
                        style={{ width: '100%' }}
                    />
                    <button onClick={handleSend} className='sendMessageButton position-absolute'><i className="fas fa-paper-plane"></i>שלח</button>
                </div>
                <p>תיבת השאלות נועדה בכדי לתקשר עם המפרסם בדבר פריט מידע לא ברור או הבהרות. המפרסם אינו מחוייב לענות על כלל השאלות או למסור המלצות נוספות</p>
                <ul className='chat-term'>
                    <li>אין לספק פרטי אשראי</li>
                    <li>כדאי שלא לבצע רכישות מחוץ לפלטפורמה להגנה מירבית</li>
                </ul>
            </div>
        </Modal>
    );
};

export default ContactModal;
