import { useState, useEffect } from 'react';
import Image1 from '../assets/images/eiffel_tower.png';
import axios from 'axios';

const LovedDestinations = () => { 
    const [lovedDestinations, setLovedDestinations] = useState([]);

    const getLovedDestinations = async (userId, listingId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                params: {
                    "action": "loved_destinations"
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            
            setLovedDestinations(response.data.data);
        } catch (err) {
            console.log("Error while getting loved destinations: ", err);
        } 
    }

    const redirectToDestination = (url) => {
        window.location.href = url;
    }

    useEffect(() => {
        getLovedDestinations();
    }, []);

    return (
        <div className='lovedDestinations ptb-90'>
            <div className='container'>
                <h2 className='mb-4'>היעדים הכי אהובים</h2>
                <div className='row'>
                    {lovedDestinations.length > 0 && lovedDestinations.map(item => (
                        <div className='col-2'>
                            <div className='destinationColumn text-center'>
                                <div className='lvdDestinationImgOuter'>
                                    <a href={item.link}>
                                    <div className='destinationColumnImg'>
                                        <img src={item.image} alt='Left Image' />
                                    </div>
                                    </a>
                                </div>  
                                <h5 className='font-20 mt-2 mb-0'><a href="" className='text-black'>{item.title}</a></h5>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LovedDestinations;
