import NightLifeIcon from "../../assets/icons/NightLifeIcon";
import SubNightLifeIcon from "../../assets/icons/SubNightLifeIcon";
import { getNightLifeFeatureIcon } from "../../utils/getIcon";
import getFeatureName from "../../utils/getFeatureName";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import ImageGallery from "../ImageGallery";
import { useSelector } from "react-redux";

const NightLifeRepeater = ({data, tabRef, updateHover, openCalendar, setIsCalendarLoading, setMainLoader}) => {
    const { listingData } = useSelector((state) => state.listingData);

    return (
        <div className="tabPanel" ref={tabRef}>
            <div className="aboutTabPanel">
                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        <NightLifeIcon />
                    </div>
                    <h3>חיי לילה</h3>                    
                </div>
                {/* <CalendarIcon height={40} width={40} /> */}
                <div className="repeaterFields">
                        {data && (
                            data.map((item, key) => (
                                <div className={`singleRepeater rz_night${key} mb-5`} key={`nightlife-${key}`}
                                    onMouseEnter={() => updateHover({rzType: "rz_night", placeId: item?.rz_night_life_full_address?.place_id})}
                                    onMouseLeave={() => updateHover({})}
                                >
                                    <div className="singleListingPanelTitleIcon d-flex">                                        
                                        <div className="title_icon subpanelIcon">
                                            <SubNightLifeIcon />
                                        </div>
                                        <div className="singleListingPanelSummary">
                                            <div className="reapterTitle">
                                                <h5>{item.night_life_name}</h5>
                                                {listingData?.id !== 17147 && (
                                                    <CalendarIcon name={item.night_life_name} height={40} width={40} accId={"rz_night_life"} index={key} itemData={item} openCalendar={openCalendar} setIsCalendarLoading={setIsCalendarLoading} setMainLoader={setMainLoader} />
                                                )}
                                            </div>
                                            <div className="repeaterAddress">
                                                {item?.rz_night_life_full_address?.address} - <a href={
                                                    `https://www.google.com/maps/search/?api=1&query=${item?.rz_night_life_full_address?.address}&query_place_id=${item?.rz_night_life_full_address?.place_id}`
                                                }>צפיה במפה</a>
                                            </div>
                                            {item?.night_life_gallery && (
                                                <div className="repeaterGalleryData">
                                                    <ImageGallery
                                                        images={item?.night_life_gallery}
                                                        showBadge={false}
                                                        rec_price_range = {item?.rec_price_range[0]}
                                                    />
                                                </div>
                                            )}
                                            <div className="repeaterHighLightFeatures d-flex">
                                                {item?.nl_features_highlight && (
                                                    item?.nl_features_highlight.map((hightLight, hightLightKey) => ( 
                                                        <div className="listingTabFeatureItem" key={hightLightKey}>
                                                            <div className="highLightFeature d-flex flex-column align-items-center gap-1">
                                                                <div className="featureIcons"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: getNightLifeFeatureIcon(hightLight) && getNightLifeFeatureIcon(hightLight)[hightLight]
                                                                    }}
                                                                />
                                                                
                                                                <p>{getFeatureName(hightLight,'night_life')} </p>                                                    
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                            <div className="reapterDescription text-end"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.rz_attractions_description
                                                }}
                                            />
                                            <div className="listingTabActionButtons d-flex">
                                                {item.rz_night_life_reservaction_link !== "" &&(
                                                    <div className="reservactionLink">
                                                        <a href={item.rz_night_life_reservaction_link} className="btn btn-secondary">להזמנה</a>
                                                    </div>
                                                )}

                                                {item.rz_night_life_website_link !== "" &&(
                                                    <div className="reservactionLink">
                                                        <a href={item.rz_night_life_website_link} className="btn btn-primary">לאתר</a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>   
                                </div>
                            ))
                        )}
                </div>
            </div>
            <hr />
        </div>
    );
}

export default NightLifeRepeater;