import AttractionIcon from "../../assets/icons/AttractionIcon";
import SubAttractionIcon from "../../assets/icons/SubAttractionIcon";
import { getAttrFeatureIcon } from "../../utils/getIcon";
import getFeatureName from "../../utils/getFeatureName";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import ImageGallery from "../ImageGallery";
import { useSelector } from "react-redux";

const AttractionsRepeater = ({data, tabRef, updateHover, openCalendar, setIsCalendarLoading, setMainLoader}) => {
    const rec_price_range = data[0]?.rec_price_range[0];
    const { listingData } = useSelector((state) => state.listingData);

    return (
        <div className="tabPanel" ref={tabRef}>
            <div className="aboutTabPanel">
                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        <AttractionIcon />
                    </div>
                    <h3>אטרקציות</h3>                    
                </div>
                {/* <CalendarIcon height={40} width={40} /> */}
                <div className="repeaterFields">
                        {data && (
                            data.map((item, key) => (
                                <div className={`singleRepeater rz_attractions${key} mb-5`} key={`attraction-${key}`}
                                    onMouseEnter={() => updateHover({rzType: "rz_attractions", placeId: item?.rz_attractions_full_address?.place_id})}
                                    onMouseLeave={() => updateHover({})}
                                >
                                    <div className="singleListingPanelTitleIcon d-flex">                                        
                                        <div className="title_icon subpanelIcon">
                                            <SubAttractionIcon />
                                        </div>
                                        <div className="singleListingPanelSummary">
                                            <div className="reapterTitle">
                                                <h5>{item.rz_attractions_name}</h5>
                                                {listingData?.id !== 17147 && (
                                                    <CalendarIcon name={item.rz_attractions_name} height={40} width={40} accId={"rz_attractions"} index={key} itemData={item} openCalendar={openCalendar} setIsCalendarLoading={setIsCalendarLoading} setMainLoader={setMainLoader} />
                                                )}
                                            </div>
                                            <div className="repeaterAddress">
                                                {item?.rz_attractions_full_address?.address} - <a href={
                                                    `https://www.google.com/maps/search/?api=1&query=${item?.rz_attractions_full_address?.address}&query_place_id=${item?.rz_attractions_full_address?.place_id}`
                                                }>צפיה במפה</a>
                                            </div>
                                            {item?.rz_attractions_gallery && (
                                                <div className="repeaterGalleryData">
                                                    <ImageGallery
                                                        images={item?.rz_attractions_gallery}
                                                        showBadge={false}
                                                        rec_price_range ={rec_price_range}
                                                    />
                                                </div>
                                            )}
                                            <div className="repeaterHighLightFeatures d-flex">
                                                {item?.rz_attr_features_highlight && (
                                                    item?.rz_attr_features_highlight.map((hightLight, hightLightKey) => (
                                                        <div className="listingTabFeatureItem" key={hightLightKey}>
                                                            <div className="highLightFeature d-flex flex-column align-items-center gap-1">
                                                                <div className="featureIcons"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: getAttrFeatureIcon(hightLight) && getAttrFeatureIcon(hightLight)[hightLight]
                                                                    }}
                                                                />
                                                                
                                                                <p>{getFeatureName(hightLight,'attr')} </p> 
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                            <div className="reapterDescription"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.rz_attractions_description
                                                }}
                                            />
                                            <div className="listingTabActionButtons d-flex">
                                                {item.rz_attractions_reservaction_link !== "" &&(
                                                    <div className="reservactionLink">
                                                        <a href={item.rz_attractions_reservaction_link} className="btn btn-secondary">להזמנה</a>
                                                    </div>
                                                )}

                                                {item.rz_attractions_website_link !== "" &&(
                                                    <div className="reservactionLink">
                                                        <a href={item.rz_attractions_website_link} className="btn btn-primary">לאתר</a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            ))
                        )}
                </div>
            </div>
            <hr />
        </div>
    );
}

export default AttractionsRepeater;