import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Galleria } from 'primereact/galleria';
import { Carousel } from 'primereact/carousel';

const ImageGallery = ({ images, showBadge = false, title, badgeText, rec_price_range, isReview = false, isbannerGallery=false }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
    const [liteboxTitle, setliteboxTitle] = useState("");
    const [liteboxSource, setliteboxSource] = useState("");

    const openLightbox = (id, index) => {
        getAcfCredit(id);
        setSelectedIndex(index);
        setIsLightBoxOpen(true);
    };

    const openReviewLightbox = (index) => {
        setSelectedIndex(index);
        setIsLightBoxOpen(true);
    };

    const closeLightbox = () => {
        setSelectedIndex(0);
        setIsLightBoxOpen(false);
    };

    const showNext = (e) => {
        e.stopPropagation();
        const nextIndex = (selectedIndex + 1) % images.length;

        setSelectedIndex(nextIndex);
        getAcfCredit(images[nextIndex]?.id);
    };

    const showNextReview = (e) => {
        e.stopPropagation();
        const nextIndex = (selectedIndex + 1) % images.length;

        setSelectedIndex(nextIndex);
        getAcfCredit(images[nextIndex]?.id);
    };

    const showPrev = (e) => {
        e.stopPropagation();

        const prevIndex = (selectedIndex - 1 + images.length) % images.length;

        setSelectedIndex(prevIndex);
        getAcfCredit(images[prevIndex]?.id);
    };

    const showPrevReview = (e) => {
        e.stopPropagation();

        const prevIndex = (selectedIndex - 1 + images.length) % images.length;

        setSelectedIndex(prevIndex);
        getAcfCredit(images[prevIndex]?.id);
    };

    const itemTemplate = (item) => {
        return <img src={item.url} onClick={() => openReviewLightbox(selectedIndex)} alt={item.name} style={{ width: '100%', display: 'block', maxHeight: "180px" }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.url} alt={item.name} style={{ display: 'block',maxHeight: "180px" }} />;
    }

    const getAcfCredit = async (id) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/wp-json/wp/v2/media/${id}`,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            
            if (response.data) {
                var data = response?.data;
                setliteboxSource(data?.acf_credit?.source)
                setliteboxTitle(data?.acf_credit?.name)
            }

        } catch (err) {
            console.error("Error fetching recommendation data:", err);
        }
    };


    const [priceLabel, setPriceLabel] = useState('');

    useEffect(() => {
        if (rec_price_range) {
            const price = rec_price_range;

            let coloredDollarSigns = ''; // To hold the dollar signs with color

            switch (price) {
                case 'free':
                    coloredDollarSigns = (
                        <span className="price-free">Free</span> // Label for free items
                    );
                    break;
                case 'low':
                    coloredDollarSigns = (
                        <>
                            <span className="price-filled">$</span>
                            <span className="price-empty">$</span>
                            <span className="price-empty">$</span>
                        </>
                    );
                    break;
                case 'medium':
                    coloredDollarSigns = (
                        <>
                            <span className="price-filled">$</span>
                            <span className="price-filled">$</span>
                            <span className="price-empty">$</span>
                        </>
                    );
                    break;
                case 'high':
                    coloredDollarSigns = (
                        <>
                            <span className="price-filled">$</span>
                            <span className="price-filled">$</span>
                            <span className="price-filled">$</span>
                        </>
                    );
                    break;
                default:
                    coloredDollarSigns = (
                        <>
                            <span className="price-empty">$</span>
                            <span className="price-empty">$</span>
                            <span className="price-empty">$</span>
                        </>
                    );
            }

            setPriceLabel(coloredDollarSigns); // Set the dollar signs with color
        }
    }, [rec_price_range]);

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 641;

    return (
        <div className={`container ${isbannerGallery && "topBannerGallery"}`}>
            <div className="imageGalleryOuter position-relative">
                {images && (<>
                    <div className={`imageGallery ${isMobile ? "mobileSlider" : ""} ${isReview && "reviewGallery"}`} itemCount={images.length}>
                        {isReview ? (
                            images.map((image, index) => (
                                <div key={index} className="gallery-item" onClick={() => openReviewLightbox(index)} style={{ backgroundImage: `url(${image})` }}>
                                    {/* <img src={image.url} alt={image.title} /> */}
                                </div>
                            ))
                        ) : (
                            isMobile ? (
                                // <Galleria 
                                //     value={images} 
                                //     numVisible={5} 
                                //     circular 
                                //     style={{ maxWidth: '100%', maxHeight: "324" }}
                                //     showThumbnails={false} 
                                //     showItemNavigators={false}
                                //     item={itemTemplate} 
                                //     thumbnail={thumbnailTemplate}
                                //     showIndicators={true}
                                //     showIndicatorsOnItem={true}
                                //     activeIndex={selectedIndex} 
                                //     onItemChange={(e) => setSelectedIndex(e.index)}
                                // />

                                <Carousel 
                                    value={images} 
                                    numScroll={1}
                                    numVisible={1}
                                    itemTemplate={itemTemplate} 
                                    showItemNavigators={false}
                                />
                            ) : (
                                images.map((image, index) => (
                                    <div key={index} className="gallery-item" onClick={() => openLightbox(image.id, index)} style={{ backgroundImage: `url(${image.url})` }}>
                                        {/* <img src={image.url} alt={image.title} /> */}
                                    </div>
                                ))
                            )
                        )}

                        {(selectedIndex !== null && isLightBoxOpen) && (
                            <div className={`lightbox ${isReview && "reviewLightBox"}`} onClick={closeLightbox}>
                                <span className="close" onClick={closeLightbox}>&times;</span>
                                <span className="prev" onClick={isReview ? showPrev : showPrevReview}>&#10094;</span>
                                <img className="lightbox-image" src={isReview ? images[selectedIndex] : images[selectedIndex].url} alt={isReview ? images[selectedIndex] : images[selectedIndex].title} />
                                <span className="next" onClick={isReview ? showNext : showNextReview}>&#10095;</span>
                                <div className="lightboxTitle">
                                    <p>
                                        {(!isReview && liteboxTitle != "") && (
                                            <>
                                                <a href={liteboxSource}>קרדיט</a> | {liteboxTitle} - קרדיטים
                                            </>
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>                    
                </>)}

                {showBadge && (
                    <span className="planning_badges  position-absolute"> {badgeText} </span>
                )}
                {rec_price_range && (
                    <span className="planning_badges  position-absolute"> {priceLabel}</span>
                )}
            </div>
            <p className='text-center' style={{ width: "100%" }}>
                {title && title?.length > 0 && (
                    <>
                        קרדיטים - {title.join(", ")}
                    </>
                )}
            </p>
        </div>
    );
}

export default ImageGallery;