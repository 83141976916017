import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import SingleListing from './pages/SingleListing';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Provider } from 'react-redux';
import store from './utils/store';
import CalendarExample from './components/CalendarExample';
import HomePage from './pages/Home';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PrimeReactProvider>
          <Router>
              <Routes>
                <Route path="/listing/:listing_id" element={<SingleListing />} />
                <Route path="/vacay/listing/:listing_id" element={<SingleListing />} />
                <Route path="/testpage" element={<CalendarExample />} />
                <Route path="/" element={<HomePage />} />
              </Routes>
          </Router>
        </PrimeReactProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
