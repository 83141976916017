const ShoppingMapMarker = ({height=100, width=72, color="#000000"}) => {
    return (
        <div style={{backgroundColor: "#fff", borderRadius: "100%"}}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={width} height={height} viewBox="0 0 100.000000 100.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
                    <path d="M375 981 c-176 -51 -292 -162 -350 -334 -119 -356 224 -725 588 -631 187 48 323 184 371 371 91 352 -261 695 -609 594z m184 -185 c29 -16 46 -45 56 -99 5 -23 15 -38 30 -45 42 -19 52 -51 70 -219 19 -174 14 -212 -26 -233 -23 -13 -355 -13 -378 0 -40 21 -45 59 -26 233 18 171 28 200 73 220 22 10 27 19 29 50 5 87 92 134 172 93z"/>
                    <path d="M439 761 c-27 -27 -29 -35 -29 -100 0 -41 4 -71 10 -71 6 0 10 16 10 35 l0 35 80 0 80 0 0 36 c0 26 -8 43 -29 65 -40 39 -82 39 -122 0z"/>
                    <path d="M417 499 c-31 -23 -34 -47 -12 -89 16 -31 74 -80 95 -80 38 0 110 84 110 129 0 28 -48 63 -74 54 -12 -3 -28 -7 -36 -7 -8 0 -24 4 -36 7 -14 5 -29 1 -47 -14z m71 -21 c9 -9 15 -9 24 0 17 17 53 15 68 -3 18 -22 -4 -69 -47 -102 l-34 -26 -36 30 c-43 34 -61 76 -43 98 15 18 51 20 68 3z"/>
                </g>
            </svg>
        </div>
    )
}

export default ShoppingMapMarker;