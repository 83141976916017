import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DocumentIcon from '../assets/images/locatioon-list-icon.png';
import StarIcon from '../assets/images/star_icon.svg';
import LocationIcon from '../assets/images/location-Icon-circle.png';
import { Carousel } from 'primereact/carousel';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import CommonModal from './CommonModal';

const Travelers = ({showLoader}) => {
    const [travelers, setTravelers] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [width, setWidth] = useState(window.innerWidth);
    const [slidesPerGroup, setSlidesPerGroup] = useState(3);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([]);
    const [clickedId, setClickedId] = useState(0);

    const sliderRef = useRef();

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                    params: {
                        'action': 'travelers_recommended'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setTravelers(response.data.data);
                // setTotalPages(Math.ceil(response.data.data.length/5));
            } catch (error) {
                console.error('Error fetching travelers data', error);
            }
        };

        fetchData();
    }, []);

    const fetchPopupData = async (id) => {
        showLoader(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                params: {
                    'action': 'country_popup',
                    'user_id': id 
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setModalContent(response.data);
            showLoader(false);
            setIsModalOpen(true);
            // setTotalPages(Math.ceil(response.data.data.length/5));
        } catch (error) {
            showLoader(false);
            console.error('Error fetching travelers data', error);
        }
    };

    console.log("modalContent: ", modalContent);

    // const responsiveOptions = [
    //     {
    //         breakpoint: '1400px',
    //         numVisible: 4,
    //         numScroll: 1
    //     },
    //     {
    //         breakpoint: '992px',
    //         numVisible: 3,
    //         numScroll: 1
    //     },
    //     {
    //         breakpoint: '767px',
    //         numVisible: 3,
    //         numScroll: 2
    //     },
    //     {
    //         breakpoint: '575px',
    //         numVisible: 1,
    //         numScroll: 1
    //     }
    // ];

    const responsiveOptions = {
        1400: {
            breakpoint: '1400px',
            slidesPerView: 5
        },
        1199: {
            breakpoint: '1199px',
            slidesPerView: 4
        },
        767: {
            breakpoint: '991px',
            slidesPerView: 3
        },
        575: {
            breakpoint: '575px',
            slidesPerView: 1
        },
        250: {
            breakpoint: '250px',
            slidesPerView: 1
        }
    };

    const updateVisible = (width) => {
        var numberOfVisible = Math.ceil((travelers.length)/5);
        var slides = 3;
        if(width <= 1400 && width > 1199){
            
            numberOfVisible = Math.ceil((travelers.length) / 3);
            slides = 3;
        }else if(width <= 1199 && width > 767){

            numberOfVisible = Math.ceil((travelers.length) /2);
            slides = 2;
        }else if(width <= 767 && width > 575){
            
            numberOfVisible = Math.ceil((travelers.length) /2);
            slides = 2;
        }else if(width <= 575){

            numberOfVisible = Math.ceil((travelers.length) /1);
            slides = 1;
        }else{
            // console.log("else")
            // numberOfVisible = travelers /3;
        }

        setTotalPages(numberOfVisible);
        setSlidesPerGroup(slides);
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setIsMobile(window.innerWidth < 768);
        updateVisible(window.innerWidth)
    }

    const openDocumentModal = (id) => {
        setClickedId(id);
        fetchPopupData(id);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, []);

    useEffect(() => {
        if(travelers.length > 0){
            updateVisible(width)
        }
    }, [travelers])

    // console.log("isMobile: ", isMobile);

    const itemTemplate = (traveler) => {
        return ( <>
            <div className='travelersCardOuter h-100'>
                <div className='travelersCard travelersUserInfo d-flex flex-column h-100'>
                    <div className='travelersUserImage'>
                        <img src={traveler.avatar} alt={traveler.display_name} className='traveler-avatar' />
                    </div>    
                    <h5 className='mt-2 mb-3 my-md-3'>
                        <Link to={traveler.publisher_link} target='_blank' rel='noopener noreferrer' className='text-dark'>{traveler.display_name}</Link>
                    </h5>
                    <hr />
                    <ul className='font-12 mt-2'>
                        <li className='d-flex align-items-center'>
                            <img src={DocumentIcon} alt='icon' />
                            {traveler.active_listings} מסלולים באתר
                        </li>
                        {traveler.rating !== "" && (
                            <li className='d-flex align-items-center'>
                                <img src={StarIcon} alt='icon' />
                                {traveler.rating} דירוג של 
                            </li>
                        )}

                        {parseInt(traveler.active_listings) > 0 && (
                            <li className={`d-flex align-items-center countryRegion ${(traveler.user_id === clickedId && "clicked")}`} onClick={() => openDocumentModal(traveler.user_id)}>
                                <img src={LocationIcon} alt='icon' />
                                המדינות שלי
                            </li>
                        )}
                    </ul>
                    <a href={traveler.publisher_link} className='btn btn-primary'>צפייה במסלולים</a> 
                </div>                
            </div>            
            </>);
    };

    // Function to render grid layout for mobile
    const renderGrid = () => {
        return (
            <>
                {travelers.map(traveler => (
                    <div key={traveler.id} className='travelersCardMob'>
                        {itemTemplate(traveler)}
                    </div>
                ))}
            </>
        );
    };



    return (
        <div className='travelers pb-90'>
            <div className='container sliderSectionHead'>
                <div className='d-flex align-items-center mb-5 sliderTitleNav'>
                    <h2 className='mb-0'>טרוולרז מומלצים</h2>
                    <div className='leftNav d-flex align-items-center'>
                        <h6 className='mb-0 slidesCount'>{currentPage + 1}/{totalPages}</h6>
                        <ul className='sliderNavButtons d-flex'>
                            <li>
                                <button className={`prevBtn m-0 ${sliderRef?.current?.swiper.activeIndex === 0 ? "disabled" : ""}`} onClick={() => sliderRef?.current?.swiper.slidePrev()}><i className="fa-solid fa-angle-right"></i></button>
                            </li>
                            <li>
                                <button className={`nextBtn m-0 ${sliderRef?.current?.swiper.isEnd ? "disabled" : ""}`} onClick={() => sliderRef?.current?.swiper.slideNext()}><i className="fa-solid fa-angle-left"></i></button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='travelersInner'>                    
                    <div className='travelersSlider commonHomeSlider'>
                        {isMobile ? (
                            renderGrid()
                        ) : (
                            // <Carousel 
                            //     value={travelers} 
                            //     numVisible={5} 
                            //     numScroll={5} 
                            //     showIndicators={false} 
                            //     responsiveOptions={responsiveOptions} 
                            //     itemTemplate={itemTemplate} 
                            //     onPageChange={(e) => setCurrentPage(parseInt(e.page))}
                            //     page={currentPage}
                            // />

                            <Swiper
                                ref={sliderRef}
                                spaceBetween={0}
                                slidesPerView={5}
                                slidesPerGroup={slidesPerGroup}
                                speed={500}
                                pagination={{ clickable: true }}
                                rtl={true}
                                modules={[Navigation, Pagination]}
                                onSlideChange={(slide) => setCurrentPage(Math.ceil(slide.activeIndex/slidesPerGroup))}
                                breakpoints={responsiveOptions}
                            >
                                {travelers.map((traveler, index) => (
                                    <SwiperSlide key={index}>
                                        {itemTemplate(traveler)}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </div>
                {/* <a href='' className='btn btn-primary mt-4'>צפייה במסלולים</a>   */}
            </div>

            {isModalOpen && (
                <CommonModal className='test' onClose={() => setIsModalOpen(false)}>
                    <div className='profileCountryRegion text-center'>
                        <h4 className='text-center m-0'>המדינות של {modalContent?.user_name}</h4>
                        <div className='smallBlackdivide'></div>
                        <p>אלו המדינות והאזורים בהם תוכלו למצוא Travel Kit</p>
                        <div className='trevalKitRegion'>
                            {modalContent?.data.length > 0 ? modalContent?.data.map((item, key) => (
                                <div className='commonTrevalKitRegion'>
                                    <h5>{item?.countries[0]}</h5>
                                    {item?.cities.map(city => (
                                        <p className='m-0'>{city}</p>
                                    ))}
                                </div>
                            )) : <p className='m-0'>מיקומי מסלול לא נמצאו</p>}
                            {/* <div className='commonTrevalKitRegion'>
                                <h5>איטליה</h5>
                                <p className='m-0'>רומא</p>
                            </div>
                            <div className='commonTrevalKitRegion'>
                                <h5>גאורגיה | ארמניה</h5>
                                <p className='m-0'>טביליסי | ירוואן</p>
                            </div>
                            <div className='commonTrevalKitRegion'>
                                <h5>ספרד</h5>
                                <p className='m-0'>ברצלונה</p>
                            </div>                             */}
                        </div>
                    </div>
                </CommonModal>
            )}
        </div>
    );
};

export default Travelers;
