import MapIcon from "../assets/icons/MapIcon";

const SummaryMapTabPanel = ({data}) => {
    return (
        <div className="tabPanel">
            <div className="aboutTabPanel">

                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        <MapIcon />
                    </div>
                    <h3>מפה מפורטת</h3>                    
                </div>

                <div className="singleListingPanelSummary tabDescription">
                    <p>תקבלו לינק למפה מ-Google Maps שתוכלו להשתמש בה בחשבון ה-Google Maps שלכם בנייד  עם כל המלונות, מסעדות, אטרקציות, חיי לילה, שופינג ועוד כך שבכל רגע תוכלו לראות מה קרוב אליכם ולבנות מסלול נוח לכל יום</p>
                </div>
            </div>
        </div>
    );
}

export default SummaryMapTabPanel;