import icons from "./icons";

const getLodgingFeatureIcon = (name) => {
    const icon = icons.acco_features_icon[name];
    return icon;
}

const getCulinaryFeatureIcon = (name) => {
    const icon = icons.cul_features_icon[name];
    return icon;
}

const getAttrFeatureIcon = (name) => {
    const icon = icons.attr_features_icon[name];
    return icon;
}

const getNightLifeFeatureIcon = (name) => {
    const icon = icons.night_life_features_icon[name];
    return icon;
}

const getActivityTypeIcons = (name) => {
    const icon = icons.activity_type_icons[name];
    return icon;
}

const getShoppingFeatureIcons = (name) => {
    const icon = icons.shop_features_icon[name];
    return icon;
}

export {
    getLodgingFeatureIcon,
    getCulinaryFeatureIcon,
    getAttrFeatureIcon,
    getNightLifeFeatureIcon,
    getActivityTypeIcons,
    getShoppingFeatureIcons
}
