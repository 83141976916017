import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import { Galleria } from 'primereact/galleria';
import TimeIcon from "../assets/icons/TimeIcon";
import LocationIcon from "../assets/icons/LocationIcon";
import GlobeIcon from "../assets/icons/GlobeIcon";
import { Carousel } from 'primereact/carousel';
import CommonModal from './CommonModal';
import exlaimIcon from "../assets/images/exclaimIcon.png";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

const RecomendedVacations = ({showLoader}) => {
    const [vacations, setVacations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [width, setWidth] = useState(window.innerWidth);
    const [slidesPerGroup, setSlidesPerGroup] = useState(3);
    const sliderRef = useRef();

    useEffect(() => {
        const fetchVacations = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                    params: {
                        "action": "listing"
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                setVacations(response.data);
                // setTotalPages(response.data.length/3)
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchVacations();
    }, []);

    const openListingInfoModal = async (userId, listingId) => {
        showLoader(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                params: {
                    "action": "popup",
                    "listing_id": listingId
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            
            setModalContent(response.data);
            showLoader(false);
            setIsModalOpen(true);
        } catch (err) {
            setError(err.message);
            showLoader(false);
        } 
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    const itemTemplate = (item) => {
        return (<div>
                    <a href={item.link}>
                        <img src={item.src} alt={item.src} style={{ width: '100%', display: 'block' }} />
                    </a>

                    <div className='userProfileModal' onClick={() => openListingInfoModal(item?.user_data?.user_id, item.listingId)}>
                        <img src={item?.user_data?.user_avatar} />
                    </div>

                    {item.listingId === 17147 && (
                        <span className='listingBadge'>חינם</span>
                    )}
                        
                </div>);
    };

    // const responsiveOptions = [
    //     {
    //         breakpoint: '1400px',
    //         numVisible: 3,
    //         numScroll: 3
    //     },
    //     {
    //         breakpoint: '1199px',
    //         numVisible: 3,
    //         numScroll: 3
    //     },
    //     {
    //         breakpoint: '767px',
    //         numVisible: 2,
    //         numScroll: 2
    //     },
    //     {
    //         breakpoint: '575px',
    //         numVisible: 1,
    //         numScroll: 1
    //     }
    // ];

    const responsiveOptions = {
        767: {
            breakpoint: '767px',
            slidesPerView: 3
        },
        250: {
            breakpoint: '250px',
            slidesPerView: 1.3
        }
    };

    const updateVisible = (width) => {
        var numberOfVisible = Math.ceil((vacations.length)/3);
        var slides = 3;
        if(width <= 1400 && width > 1199){
            
            numberOfVisible = Math.ceil((vacations.length) / 3);
            slides = 3;
        }else if(width <= 1199 && width > 767){

            numberOfVisible = Math.ceil((vacations.length) /2);
            slides = 2;
        }else if(width <= 767 && width > 575){
            
            numberOfVisible = Math.ceil((vacations.length) /2);
            slides = 2;
        }else if(width <= 575){

            numberOfVisible = Math.ceil((vacations.length) /1);
            slides = 1;
        }else{
            // console.log("else")
            // numberOfVisible = travelers /3;
        }

        setTotalPages(numberOfVisible);
        setSlidesPerGroup(slides);
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        updateVisible(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, []);

    useEffect(() => {
        if(vacations.length > 0){
            updateVisible(width)
        }
    }, [vacations])

    const vacationTemplate = (vacation) => {
        return (
            <div className='commonListingColumnOuter' key={vacation.listing_id} style={styles.item}>
                <Galleria
                    value={vacation.gallery.map(img => ({
                        src: img.full_image,
                        thumbnail: img.thumbnail,
                        title: vacation.listing_title,
                        user_data: vacation?.user_data,
                        listingId: vacation.listing_id,
                        link: vacation.listing_link
                    }))}
                    numVisible={5}
                    circular
                    style={{ maxWidth: '640px' }}
                    showThumbnails={false}
                    showItemNavigators
                    item={itemTemplate}
                />
                <div className='commonListingContent' style={styles.content}>

                    <h6>
                        <a href={vacation.listing_link} dangerouslySetInnerHTML={{__html: vacation.listing_title}}></a>
                    </h6>
                    
                    <div className='commonListingDesc'>
                        <p dangerouslySetInnerHTML={{__html: vacation.about_the_trip}}></p>    
                    </div>                            
                    <ul className='listingDataList d-flex flex-wrap align-items-center gap-2'>
                        <li>
                            <TimeIcon />
                            {vacation.listing_trip_days}
                        </li>
                        <li>
                            <GlobeIcon />
                            {vacation.cities}
                        </li>
                        <li>
                            <LocationIcon />
                            {vacation.listing_location}
                        </li>                                    
                    </ul>
                    <div className='commonListingPrice'>
                     {vacation.listing_id === 17147 ? 'חינם' : `${vacation.price_for_purchasing}₪`}
                    </div>
 
                </div>
            </div>
        );
    };

    const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'];

    if (loading) return "";
    if (error) return "";

    return (
        <div className='ptb-90 commonListingOuterWrap'>
            <div className='container sliderSectionHead'>
                <div className='d-flex align-items-center mb-5 sliderTitleNav'>
                    <h2 className='mb-0'>חופשות מומלצות מהעולם</h2>                    
                    <div className='leftNav d-flex align-items-center'>
                        <h6 className='mb-0 slidesCount'>{currentPage + 1}/{totalPages}</h6>
                        <ul className='sliderNavButtons d-flex'>
                            <li>
                                <button className={`prevBtn m-0 ${sliderRef?.current?.swiper.activeIndex === 0 ? "disabled" : ""}`} onClick={() => sliderRef?.current?.swiper.slidePrev()}><i className="fa-solid fa-angle-right"></i></button>
                            </li>
                            <li>
                                <button className={`nextBtn m-0 ${sliderRef?.current?.swiper.isEnd ? "disabled" : ""}`} onClick={() => sliderRef?.current?.swiper.slideNext()}><i className="fa-solid fa-angle-left"></i></button>
                            </li>
                        </ul>
                    </div>
                </div>                
                <div className="commonListingRowOuter commonHomeSlider">
                    {/* <Carousel 
                        value={vacations} 
                        numVisible={3} 
                        numScroll={3} 
                        showIndicators={true} 
                        responsiveOptions={responsiveOptions} 
                        itemTemplate={vacationTemplate}
                        onPageChange={(e) => setCurrentPage(parseInt(e.page))}
                        page={currentPage}
                    /> */}

                    <Swiper
                        ref={sliderRef}
                        spaceBetween={0}
                        slidesPerView={3}
                        slidesPerGroup={slidesPerGroup}
                        speed={500}
                        pagination={{ clickable: true }}
                        rtl={true}
                        modules={[Navigation, Pagination]}
                        onSlideChange={(slide) => setCurrentPage(Math.ceil(slide.activeIndex/slidesPerGroup))}
                        breakpoints={responsiveOptions}
                    >
                        {vacations.map((vacation, index) => (
                            <SwiperSlide key={index}>
                                {vacationTemplate(vacation)}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>       
            </div>

            {isModalOpen && (
                <CommonModal 
                    onClose={handleModalClose}
                >
                    <div className='infoTop recpListingInfoModal'>
                        <div className='topRight'>
                            <div className='topRightProfileImg'>
                                <img src={modalContent?.data?.user_info?.avatar} />
                            </div>                            
                            <h5>{modalContent?.data?.user_info?.display_name}</h5>
                        </div>

                        <div className='topLeft'>
                            <div className='actingListing'>
                                <h4>{modalContent?.data?.user_info?.active_listings}</h4>
                                <p>חופשות מפורסמות</p>
                            </div>

                            <hr />

                            <div className='listingRating'>
                                <h4>טרם נקבע</h4>
                                <p>{modalContent?.data?.listing_rating} דירוג</p>
                            </div>

                            <hr />

                            <div className='listingSocialLinks d-flex align-items-center gap-3'>
                                {modalContent?.data?.social_links?.facebook?.url !== "" && (
                                    <div className='facebookIcon'>
                                        <a href={modalContent?.data?.social_links?.facebook?.url}>
                                            <img src={modalContent?.data?.social_links?.facebook?.icon} />
                                        </a>
                                    </div>
                                )}

                                {modalContent?.data?.social_links?.instagram?.url !== "" && (
                                    <div className='instaIcon'>
                                        <a href={modalContent?.data?.social_links?.instagram?.url}>
                                                <img src={modalContent?.data?.social_links?.instagram?.icon} />
                                        </a>
                                    </div>
                                )}

                                {modalContent?.data?.social_links?.tiktok?.url !== "" && (
                                    <div className='tiktokIcon'>
                                        <a href={modalContent?.data?.social_links?.tiktok?.url}>
                                            <img src={modalContent?.data?.social_links?.tiktok?.icon} />
                                        </a>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    <div className='includedInPlan'>
                        <h4>מה כלול בתכנון?</h4>
                        <p>תכנון ל-{modalContent?.data?.trip_days} ימי חופשה</p>
                    </div>

                    <div className='recommendations d-flex gap-3 flex-column'>
                            {modalContent?.data?.recommendations.length > 0 && modalContent?.data?.recommendations.map(item => (
                                <div className='singleRecommendation d-flex gap-0 align-items-center'>
                                    <img src={item?.icon} />
                                    <h5 className='ms-1'>{item?.name} </h5>
                                    <p> - {item?.count } {" המלצות"} </p>
                                </div>
                            ))}
                    </div>                    
                    <div className='listingBottom recpListingInfoModalFooter d-flex align-items-center gap-4'>
                        <img src={exlaimIcon} />
                        <p className='m-0'>סה"כ</p>
                        <div className='totalRecommendations'>
                            {modalContent?.data?.total_recommendations}
                        </div>

                        <span className='m-0'> המלצות | כ - <strong>{modalContent?.data?.per_day_recommendations} ליום</strong></span>
                    </div>
                </CommonModal>
            )}
        </div>
    );
};

const styles = {
    grid: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',
        justifyContent: 'space-between'
    },
    item: {
        flex: '1 1 calc(33.333% - 16px)',
        boxSizing: 'border-box',
        marginBottom: '16px'
    },
    content: {
        padding: '10px'
    }
};

export default RecomendedVacations;
