const getFeatureName = (slug,name) => {

        if(name =="acc"){ 
        const data = {
            "in-the-center-of-the-city": "במרכז העיר",
            "near-a-ski-resort": "בקרבת אתר סקי",
            "near-the-beach": "בקרבת חוף ים",
            "near-the-city-center": "בקרבת מרכז העיר",
            "bar-in-the-hotel": "בר במלון",
            "swimming-pool": "בריכת שחייה",
            "all-inclusive": "הכל כלול",
            "gym": "חדר כושר",
            "direct-descent-to-the-ski-lifts": "ירידה ישירה לרכבלי הסקי",
            "for-adults-only": "למבוגרים בלבד",
            "sports-fields": "מגרשי ספורט",
            "kitchen": "מטבח",
            "restaurants-in-the-hotel": "מסעדות במלון",
            "great-for-couples": "מעולה לזוגות",
            "acc_pool": "בריכה",
            "terrace": "מרפסת",
            "suitable-for-families": "מתאים למשפחות",
            "suitable-for-babies": "מתאים לתינוקות",
            "mountain-view": "נוף להרים",
            "sea-view": "נוף לים",
            "city-view": "נוף לעיר",
            "spa": "ספא",
            "special-design": "עיצוב מיוחד",
            "quiet-at-night": "שקט בלילה",
            "kosher_hotel": "מלון כשר",
            "hotel_at_the_airport": "מלון בשדה התעופה"
        } 
        return data[slug];
    }

    if(name =="culinary"){ 
        const data = {
    "local-food": "אוכל מקומי",
    "italian": "איטלקי",
    "asian": "אסייתי",
    "coffee-shop": "בית קפה",
    "in-the-center-of-the-city": "במרכז העיר",
    "meat": "בשר",
    "ice-cream-parlor": "גלידריה",
    "vegan": "טבעוני",
    "expensive": "יקר",
    "outdoor-seating": "ישיבה חיצונית",
    "kosher": "כשר",
    "bakery": "מאפיה",
    "music-and-atmosphere": "מוזיקה ואווירה",
    "michelin-restaurant": "מסעדת מישלן",
    "chef-restaurant": "מסעדת שף",
    "deli": "מעדניה",
    "great-for-couples": "מעולה לזוגות",
    "parking-shortage": "מצוקת חניה",
    "suitable-for-a-couple-with-a-baby": "מתאים לזוג עם תינוק",
    "suitable-for-families": "מתאים למשפחות",
    "gorgeous-view": "נוף משגע",
    "sandwiches": "סנדוויצ’ים",
    "unique-design": "עיצוב ייחודי",
    "business-lunch": "עסקית צהריים",
    "food-truck": "פוד-טראק",
    "seafood": "פירות ים",
    "vegetarian": "צמחוני",
    "good-value-for-the-price": "תמורה טובה למחיר",
    "kosher_dairy": "כשר חלבי",
    "kosher_meat": "כשר בשרי",
    "pizzeria": "פיצרייה",
    "suitable_for_children": "מתאים לילדים"
    
    }
    return data[slug];

    } 

    if(name =="attr"){
        const data = {
            "craftsmanship": "בקרבת הים",
            "extreme": "אקסטרים",
            "architecture": "ארכיטקטורה",
            "ski_resort": "אתר סקי",
            "at_the_beach": "בחוף הים",
            "age-restriction": "הגבלת גיל",
            "gambling": "הימורים",
            "pets": "חיות מחמד",
            "free": "חינמי",
            "nature": "טבע",
            "you_should_combine_bicycles": "כדאי לשלב אופניים",
            "including_walking": "כולל הליכה",
            "involves_ownership": "כרוך בעלות",
            "museums_and_exhibitions": "מוזיאונים ותערוכות",
            "central_location": "מיקום מרכזי",
            "suitable_for_those_with_walking_difficultiest": "מתאים לבעלי קשיי הליכה",
            "suitable_for_children": "מתאים לילדים",
            "suitable_for_a_picnic": "מתאים לפיקניק",
            "comfortable_with_a_stroller": "נוח עם עגלה",
            "sporting": "ספורטיבי",
            "urban": "עירוני",
            "parks_and_gardens": "פארקים וגנים",
            "winter_activity": "פעילות חורף",
            "water_activity": "פעילות מים",
            "summer_activity": "פעילות קיץ",
            "plenty_of_shade": "צל בשפע",
            "local_culture": "תרבות מקומית",
            "att_memorials": "אנדרטאות",
            "att_history": "היסטורי"
    }
    return data[slug];

    }

    if(name =="night_life"){
        const data = {
    "near-the-sea": "בחוף הים",
    "bar": "בר",
    "wine-bar": "בר יין",
    "cocktail-bar": "בר קוקטיילים",
    "dance-bar": "דאנס בר",
    "requires-travel-from-the-city-center": "דורש נסיעה ממרכז העיר",
    "dj": "די-ג’יי | DJ",
    "happy-hour": "האפי האור | Happy Hour",
    "age-restriction": "הגבלת גיל",
    "expensive": "יקר",
    "sitting-outside": "ישיבה בחוץ",
    "sitting-inside": "ישיבה בפנים",
    "live-music": "מוזיקה חיה",
    "central-location": "מיקום מרכזי",
    "number-of-spaces": "מספר חללים",
    "parking-shortage": "מצוקת חניה",
    "special-place": "מקום מיוחד",
    "suitable-for-dates-and-couples": "מתאים לדייטים וזוגות",
    "unique-design": "עיצוב ייחודי",
    "international-audience": "קהל בינלאומי",
    "young-crowd": "קהל צעיר",
    "ruptop": "רופטופ | Rooftop",
    "dances": "ריקודים",
    "good-value-for-the-price": "תמורה טובה למחיר",
    "food-menu": "תפריט אוכל",
    "nl_opera": "אופרה",
    "nl_clasic_music": "קונצרט"
    }

    return data[slug];

    }

    if(name =="shopping"){
        const data = {
            "covered": "מקורה",
            "in_the_open_air": "באוויר הפתוח",
            "market_stalls": "שוק דוכנים",
            "shopping_mall": "קניון",
            "near_the_city_center": "בקרבת מרכז העיר",
            "driving_from_the_city_center": "נסיעה ממרכז העיר",
            "international_brands": "מותגים בינלאומיים",
            "local_products": "מוצרים מקומיים",
            "artists_market": "שוק אמנים",
            "food_market": "שוק אוכל",
            "vintage_and_nd_hand": "וינטג' ויד 2",
            "imitations_and_fakes": "חיקויים וזיופים",
            "suitable_for_children": "מתאים לילדים",
            "accessible_to_strollers": "נגיש לעגלות",
            "Its_worth_walking_around": "כדאי לטייל מסביב",
            "expensive": "יקר",
            "cheap": "זול",
            "open_daily": "פתוח מדי יום",
            "open_on_certain_days": "פתוח בימים מסוימים"
        }

        return data[slug];

    }
}

export default getFeatureName;

