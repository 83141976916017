// CalendarComponent.js

import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Initialize the moment localizer (required for dates)
const localizer = momentLocalizer(moment);

const CalendarExample = () => {
  // Sample event data
  const [events] = useState([
    {
      id: 0,
      title: 'Meeting with Client',
      start: new Date(2024, 8, 12, 9, 0),  // Sept 12, 2024, 9:00 AM
      end: new Date(2024, 8, 12, 11, 0),   // Sept 12, 2024, 11:00 AM
    },
    {
      id: 1,
      title: 'Team Lunch',
      start: new Date(2024, 8, 12, 12, 30), // Sept 12, 2024, 12:30 PM
      end: new Date(2024, 8, 12, 13, 30),   // Sept 12, 2024, 1:30 PM
    },
    {
      id: 2,
      title: 'Project Review',
      start: new Date(2024, 8, 12, 14, 0),  // Sept 12, 2024, 2:00 PM
      end: new Date(2024, 8, 12, 16, 0),    // Sept 12, 2024, 4:00 PM
    }
  ]);

  return (
    <div style={{ height: '500px' }}>
      <Calendar
        localizer={localizer}                 // Use moment localizer
        events={events}                       // Pass the event data
        startAccessor="start"                 // Start time for event
        endAccessor="end"                     // End time for event
        defaultView="day"                     // Default view is "day"
        views={{ day: true }}                 // Show only the "day" view
        style={{ height: 500 }}               // Set calendar height
        defaultDate={new Date(2024, 8, 12)}   // Default date (Sept 12, 2024)
        titleAccessor="title"                 // Display the title of events
      />
    </div>
  );
};

export default CalendarExample;
