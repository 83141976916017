const LodgingMapMarker = ({height=100, width=72, color="#000000"}) => {
    return (
        <div style={{backgroundColor: "#fff", borderRadius: "100%"}}><svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={width} height={height} viewBox="0 0 100.000000 100.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
            <path d="M375 981 c-181 -53 -304 -177 -359 -360 -20 -69 -20 -156 0 -234 48 -187 184 -323 371 -371 256 -67 528 102 597 370 91 353 -261 697 -609 595z m260 -274 l120 -92 0 -165 0 -165 -92 -3 -93 -3 0 101 0 100 -65 0 -65 0 0 -100 0 -101 -92 3 -93 3 -2 165 -2 165 122 92 c67 50 127 92 132 92 6 0 64 -41 130 -92z"/>
        </g>
        </svg></div>
    )
}

export default LodgingMapMarker;