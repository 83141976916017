import Layout from "./Layout";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment"; 
import Modal from "../components/Modal";
import ContactModal from "../components/ContactModal"; // Or the correct path for your ContactModal component

import ImageGallery from "../components/ImageGallery";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import ListingSummary from "../components/ListingSummary";
import LodgingTabPanel from "../components/LodgingTabPanel";
import CulinaryTabPanel from "../components/CulinaryTabPanel";
import AttractionsTabPanel from "../components/AttractionsTabPanel";
import NightLifeTabPanel from "../components/NightLifeTabPanel";
import ShoppingTabPanel from "../components/ShoppingTabPanel";
import SummaryMapTabPanel from "../components/SummaryMapTabPanel";
import TimeTableTabPanel from "../components/TimeTableTabPanel";
import WhoAmITabPanel from "../components/WhoAmITabPanel";
 
import LodgingRepeater from "../components/repeaterData/LodgingRepeater";
import CulinaryRepeater from "../components/repeaterData/CulinaryRepeater";
import AttractionsRepeater from "../components/repeaterData/AttractionsRepeater";
import NightLifeRepeater from "../components/repeaterData/NightLifeRepeater";
import ShoppingRepeater from "../components/repeaterData/ShoppingRepeater";
import DetailedMapTabPanel from "../components/DetailedMapTabPanel";
import DaysActivity from "../components/DaysActivity";
import FAQTabPanel from "../components/FAQTabPanel";
import ScrollingTabs from "../components/ScrollingTabs";
import EyeHeart from "../assets/images/eyeheart.svg";

import LeftMapSection from "../components/LeftMapSection";
import ReviewsTab from "../components/ReviewsTab";
import PodcastSection from "../components/PodcastSection";
import SimpleCalendarIcon from "../assets/icons/simpleCalendarIcon";

import { Button } from "@mui/material";
import InstagramVideo from "../components/InstagramUrl";

import { updateListingData } from "../utils/reducers/listing.reducer";
import { updateUserData } from "../utils/reducers/user.reducer";
import { useSelector, useDispatch } from "react-redux";
import SidebarCalendar from "../components/SidebarCalendar";
import { ConsoleView } from "react-device-detect";

const SingleListing = () => {
  const [listingData, setListingData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null); // Store selected time slot info
  const [name, setName] = useState(""); // Sample data for name
  const [message, setMessage] = useState("");
  const [isContactModalOpen, setIsContactModalOpen] = useState(false); // State for contact modal
  const [showBlur, setShowBlur] = useState(true);
  const [suitableTripData, setSuitableTripData] = useState([]);
  const [lessSuitableTripData, setLessSuitableTripData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [showResults, setShowResults] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const [badge, setBadge] = useState(false);
  const [displayMobileMap, setDisplayMobileMap] = useState(false);
  const [listingReviewCount, setListingReviewCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [userId, setCheckLoginUserId] = useState(0);
  const [metaData, setMetaData] = useState({
    title: "",
    description: ""
  });


  const CheckLoginUserId = async () => {
    //setIsLoading(true); // Start loading
    try {  
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/wp-json/user/check-login`
        );
        console.log("response.data.data: ",response.data.data);
        if (response.data.data.status === 200) {
            setCheckLoginUserId(response.data.data.user_id);
        }else{
            setCheckLoginUserId(0);
        }   
    } catch (err) {
        setCheckLoginUserId(0);
        console.log("err: ", err.response);

    }    
    // setIsLoading(false); // Stop loading
}; 

useEffect(() => {
    CheckLoginUserId(); // Call the login check function
}, []);

console.log("userId: ", userId);

  // const metaData = {
  //   title: "",
  //   description: ""
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();

  // const currentEnv = process.env.NODE_ENV;
  // var userId = 0;

  // if (currentEnv === "production") {
  //   console.log("production");
  //   userId = localStorage.getItem("vacaytion_user_id");
  // } else {
  //   userId = 0;
  // }

  const [hoverElement, setHoverElement] = useState({});
  const { listing_id } = useParams();
  const summaryRef = useRef();
  const whoAmIRef = useRef();
  const lodgingRef = useRef();
  const culinaryRef = useRef();
  const attractionRef = useRef();
  const nightLifeRef = useRef();
  const shoppingRef = useRef();
  const detailedRef = useRef();
  const timeTableRef = useRef();
  const faqRef = useRef();
  const reviewsRef = useRef();

  const fetchSideBarData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/wp-json/listing/sidebar`, {
        params: {
          listing_id: listingData.id,
        },
        // headers: {
        //     'Content-Type': 'application/json',
        // },

      });
      setSuitableTripData(response.data.type_of_trip);
      setLessSuitableTripData(response.data.trip_suitable_for);
    } catch (error) {
      console.error("Error fetching suitable trip data:", error);
    }
  }


  useEffect(() => {
    if(listingData){ 
      fetchSideBarData();
    }
  }, [listingData]);

  const getListingData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/wp-json/wp/v2/rz_listing?slug=${listing_id}`
      );
      if (response) {
        dispatch(updateListingData(response?.data[0]));
        setListingData(response?.data[0]);
        
        setMetaData({
          title: response?.data[0].acf?.post_title,
          description: response?.data[0].acf?.about_the_trip
        });

        setIsDataLoading(false);
        setIsLoading(false);

        checkListingBlur(response?.data[0].id, userId);
      }
    } catch (err) {
      console.log("err: ", err.response);
      setIsLoading(false);
      setIsDataLoading(false);
    }
  };

  const checkListingBlur = async (listingId, userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/wp-json/listing_access/check`,
        {
          params: {
            listing_id: listingId,
            user_id: userId,
          },
        }
      );
      if (response) {
        if (response?.data?.access === 1) {
          setShowBlur(true);
        } else {
          setShowBlur(false);
        }
      }
    } catch (err) {
      console.log("err: ", err.response);
    }
  };

  const localizer = momentLocalizer(moment);

  const handleSelect = ({ start, end }) => {
    setSelectedSlot({ start, end });
    setIsModalOpen(true);
  };

  const handleSave = (newEvent) => {
    const eventToAdd = {
      acc_title: newEvent.acc_title,
      start: new Date(newEvent.start_time),
      end: new Date(newEvent.end_time),
      allDay: false,
    };

    setEvents((prevEvents) => [...prevEvents, eventToAdd]);

    setIsModalOpen(false);
  };

  const handleSendMessage = (message) => {
    setIsContactModalOpen(false);
  };

  const handleContactButtonClick = () => {
    setIsContactModalOpen(true);
    setShowCalendar(false);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const handleCalendarButtonClick = () => {
    setShowCalendar(!showCalendar);
    setIsContactModalOpen(false);
  };

  const fetchRecommendationData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/wp-json/recommendation_list/show`,
        {
          params: { listing_id: listingData.id },
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data) {
        setSearchResults(response.data);
      }
    } catch (err) {
      console.error("Error fetching recommendation data:", err);
    }
  };
  const handleSearchInput = (event) => {
    const term = event.target.value.trim().toLowerCase();
    setSearchValue(term);
    setSearchTerm(term);

    // Filter results based on the search term
    const filtered = searchResults.filter(result =>
      result.label.toLowerCase().includes(term)
    );

    setFilteredResults(filtered);
    setShowResults(term.length > 0); // Show results if there's a search term
  };




  const handleSuggestionClick = (label, link) => {
    link = link.replace("#", ".")
    const element = document.querySelector(`${link}`);
    console.log("element: ", element);
    if (element !== null) {
      // element.scrollIntoView({ behavior: 'smooth', block: 'center' });

      const elementPosition = element.offsetTop;
      const offsetPosition = elementPosition + window.scrollY - 205;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }

    // document.querySelectorAll('.scroll-marker').forEach(marker => {
    //   const parent = marker.parentNode;
    //   parent.replaceChild(document.createTextNode(marker.textContent), marker);
    // });

    // const elements = document.body.querySelectorAll('*:not(script):not(style)');
    // let foundElement = null;

    // elements.forEach(el => {
    //   el.childNodes.forEach(node => {
    //     if (node.nodeType === Node.TEXT_NODE) {
    //       const regex = new RegExp(`(${term})`, 'gi');
    //       if (regex.test(node.textContent)) {
    //         const newHTML = node.textContent.replace(regex, '<span class="scroll-marker">$1</span>');
    //         const tempDiv = document.createElement('div');
    //         tempDiv.innerHTML = newHTML;
    //         while (tempDiv.firstChild) {
    //           el.insertBefore(tempDiv.firstChild, node);
    //         }
    //         el.removeChild(node);
    //         foundElement = el;
    //       }
    //     }
    //   });
    // });

    // // Scroll to the first found element
    // if (foundElement) {
    //   foundElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // }
    setSearchTerm(label);
    setFilteredResults([]);
    setSearchValue(label);
    setShowResults(false);



  };



  const [title, setTitle] = useState([]);

  const getTitleName = async (images) => {
    try {
      if (Array.isArray(images)) {
        let uniqueTitles = new Set(); // Using Set to store unique titles
        let uniqueSource = new Set();
        for (const image of images) {
          let id = image.id;

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/wp-json/wp/v2/media/${id}`,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          if (response.data && response?.data?.acf_credit?.name) {
            uniqueTitles.add(response?.data?.acf_credit?.name);
            setTitle(Array.from(uniqueTitles));
          }
        }



      } else {
        console.log("Images is not an array");
      }
    } catch (err) {
      console.error("Error fetching recommendation data:", err);
    }
  };

  useEffect(() => {
    if (listingData?.acf?.main_gallery) {
      getTitleName(listingData.acf.main_gallery);
    }
  }, [listingData?.acf?.main_gallery]);

  useEffect(() => {
    getListingData();
    dispatch(updateUserData({ userId }));
  }, [userId]);

  // useEffect(() => {
  //   getListingData();
  //   dispatch(updateUserData({ userId }));
  // }, []);

  useEffect(() => {
    const bodyElement = document.querySelector("body");
    if (userId == 0) {
      if (!bodyElement.classList.contains("notLoggedIn")) {
        bodyElement.classList.remove("loggedIn");
        bodyElement.classList += "notLoggedIn";
      }

    } else {
      if (!bodyElement.classList.contains("loggedIn")) {
        bodyElement.classList.remove("notLoggedIn");
        bodyElement.classList += "loggedIn";
      }
    }

    window.addEventListener("scroll", () => {
      if (window.scrollY >= 100) {
        if (!bodyElement.classList.contains("sticky")) {
          bodyElement.classList.add("sticky");
        }
      } else {
        if (bodyElement.classList.contains("sticky")) {
          bodyElement.classList.remove("sticky");
        }
      }
    });
  }, [userId])


  useEffect(() => {
    if (listingData?.id) {
      fetchRecommendationData();
    }
  }, [listingData?.id]);


  const [badgeText, setBadgeText] = useState('');


  useEffect(() => {
    if (listingData?.author) {
      axios.get(`${process.env.REACT_APP_API_URL}/wp-json/user-info/show/${listingData?.author}`)
        .then(response => {
          const planning_name = response.data.planning_name;
          if (planning_name) {
            setBadge(true);
          }
          setBadgeText(planning_name);
        })
        .catch(error => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [listingData?.author]);

  useEffect(() => {
    // Disable the input for 5 seconds when the component mounts
    const timer = setTimeout(() => {
      setIsDisabled(false);
    }, 6000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);


  return (
    <>
    {isLoading && (
      <Loader />
    )}
    {!isLoading && (
    <Layout userId={userId} listingData={listingData} showBlur={showBlur} handleCalendarButtonClick={handleCalendarButtonClick} openMobileMap={setDisplayMobileMap} isDisplayMobileMap={displayMobileMap} showCalendar={showCalendar} metaData={metaData}>

      {isDataLoading ? (
        <Loader />
      ) : (
        listingData && (
          <>
            <div className="listingNav">
              <ScrollingTabs
                summaryTab={summaryRef}
                whoAmITab={whoAmIRef}
                lodgingTab={lodgingRef}
                culinaryTab={culinaryRef}
                attractionTab={attractionRef}
                nightLifeTab={nightLifeRef}
                shoppingTab={shoppingRef}
                mapTab={detailedRef}
                calendarTab={timeTableRef}
                faqTab={faqRef}
                reviewsTab={reviewsRef}
                userId={userId}
                listingData={listingData}
                showBlur={showBlur}
              />
            </div>

            <ImageGallery
              images={listingData?.acf?.main_gallery}
              title={title}
              showBadge={badge}
              badgeText={badgeText}
              isbannerGallery={true}
            />
            <div className="container singleListingWrap">
              <div className="listingData d-flex justify-content-center gap-4">
                <div className="listingInformation">
                  <div class="stickySearchbar">
                    <div class="stickySearchbarWrap position-relative">
                      <div class="stickySearchbarInner position-relative">
                        <span className="icon position-absolute">
                          <i className="fas fa-search"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="חיפוש המלצה"
                          onChange={handleSearchInput}
                          value={searchValue}
                          disabled={isDisabled}
                        />
                      </div>
                      {searchTerm && showResults && (
                        <ul className="searchResults">
                          {filteredResults.length > 0 ? (
                            filteredResults.map((result, index) => (
                              <li key={index}>
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleSuggestionClick(result.label, result.link);
                                  }}
                                >
                                  {result.label}
                                </a>
                              </li>
                            ))
                          ) : (
                            <li>No results found.</li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>

                  <ListingSummary data={listingData} tabRef={summaryRef} showBadge={badge} listingReviewCount={listingReviewCount} />

                  <hr />

                  <LodgingTabPanel data={listingData} tabRef={lodgingRef} />
                  <CulinaryTabPanel data={listingData} tabRef={culinaryRef} />
                  <AttractionsTabPanel data={listingData} tabRef={attractionRef} />
                  <NightLifeTabPanel data={listingData} tabRef={nightLifeRef} />
                  <ShoppingTabPanel data={listingData} tabRef={shoppingRef} />
                  <SummaryMapTabPanel data={listingData} />
                  <TimeTableTabPanel data={listingData} />
                  <WhoAmITabPanel data={listingData} tabRef={whoAmIRef} userId={userId} listingData={listingData}  />

                  <div className={`blurContent ${showBlur && "showBlur"}`}>
                    <div className="blurDescription">
                      <p className="pb-2">
                        רוצים לקבל גישה לכל ההמלצות הכי שוות לחופשה שלכם כולל
                        לינה ואירוח, קולינריה, אטרקציות, חיי לילה, שופינג, לו"ז
                        מומלץ וקישור קל לראות את הכל בגוגל מפות?
                      </p>

                      <a
                        href={`${process.env.REACT_APP_API_URL}/login?listing_id=${listingData.id}`}
                        className="orangeButton radius-50 my-4"
                      >
                        לרכישה
                      </a>

                      <p className="pt-2">
                        {" "}
                        להשלמת הרכישה, יש{" "}
                        <a
                          href={`${process.env.REACT_APP_API_URL}/login/?action=register&listing_id=${listingData.id}`}
                        >
                          להירשם
                        </a>{" "}
                        או{" "}
                        <a href={`${process.env.REACT_APP_API_URL}/login/?listing_id=${listingData.id}`}>
                          {" "}
                          לאתר
                        </a>
                      </p>
                    </div>
                    <div className="repeaterfieldsData">
                      <div className="lodgingRepeaterData">
                        {listingData?.acf?.rz_accommodation[0]
                          .rz_accommodation_name.length !== 0 && (
                            <LodgingRepeater
                              data={listingData?.acf?.rz_accommodation}
                              tabRef={lodgingRef}
                              updateHover={setHoverElement}
                              openCalendar={setShowCalendar}
                              setIsCalendarLoading={setIsCalendarLoading}
                              setMainLoader={setIsLoading}
                            />
                          )}
                      </div>

                      <div className="culinaryRepeaterData">
                        {listingData?.acf?.rz_culinary[0].rz_culinary_name
                          .length !== 0 && (
                            <CulinaryRepeater
                              data={listingData?.acf?.rz_culinary}
                              tabRef={culinaryRef}
                              updateHover={setHoverElement}
                              openCalendar={setShowCalendar}
                              setIsCalendarLoading={setIsCalendarLoading}
                              setMainLoader={setIsLoading}
                            />
                          )}
                      </div>

                      <div className="attractionRepeaterData">
                        {listingData?.acf?.rz_attractions[0].rz_attractions_name
                          .length !== 0 && (
                            <AttractionsRepeater
                              data={listingData?.acf?.rz_attractions}
                              tabRef={attractionRef}
                              updateHover={setHoverElement}
                              openCalendar={setShowCalendar}
                              setIsCalendarLoading={setIsCalendarLoading}
                              setMainLoader={setIsLoading}
                            />
                          )}
                      </div>

                      <div className="attractionRepeaterData">
                        {listingData?.acf?.rz_night[0].night_life_name
                          .length !== 0 && (
                            <NightLifeRepeater
                              data={listingData?.acf?.rz_night}
                              tabRef={nightLifeRef}
                              updateHover={setHoverElement}
                              openCalendar={setShowCalendar}
                              setIsCalendarLoading={setIsCalendarLoading}
                              setMainLoader={setIsLoading}
                            />
                          )}
                      </div>

                      <div className="shoppingRepeaterData">
                        {listingData?.acf?.rz_shopping[0].rz_shopping_name
                          .length !== 0 && (
                            <ShoppingRepeater
                              data={listingData?.acf?.rz_shopping}
                              tabRef={shoppingRef}
                              updateHover={setHoverElement}
                              openCalendar={setShowCalendar}
                              setIsCalendarLoading={setIsCalendarLoading}
                              setMainLoader={setIsLoading}
                            />
                          )}
                      </div>
                    </div>

                    <DetailedMapTabPanel
                      data={listingData}
                      tabRef={detailedRef}
                    />

                    <hr />

                    <TimeTableTabPanel
                      data={listingData}
                      tabRef={timeTableRef}
                    />
                    <DaysActivity data={listingData} openCalendar={setShowCalendar} setIsCalendarLoading={setIsCalendarLoading} setMainLoader={setIsLoading} />

                    <hr />

                    <FAQTabPanel
                      data={listingData?.acf?.rz_user_ques_ans}
                      tabRef={faqRef}
                    />
                  </div>

                  <ReviewsTab
                    data={listingData}
                    tabRef={reviewsRef}
                    userId={userId}
                    updateReviewCount={setListingReviewCount}
                  />

                </div>
                <div className="listingMapSection">
                  <InstagramVideo url={listingData?.acf?.video_iframe_url} />

                  <div className="leftSidebarMap">
                    {!showBlur && (
                      <LeftMapSection
                        data={listingData?.acf}
                        hoverElement={hoverElement}
                        openCalendar={setShowCalendar}
                        displayMobileMap={displayMobileMap}
                        setDisplayMobileMap={setDisplayMobileMap}
                        setIsCalendarLoading={setIsCalendarLoading}
                        setMainLoader={setIsLoading}
                        userId={userId}
                      />
                    )}
                    <div className="leftSidebarList">
                      <div className="commonSidebarList">
                        <ul>
                          <ul>
                            {suitableTripData.length > 0 ? (
                              suitableTripData.map((trip, index) => (
                                <li key={index} style={{ direction: 'rtl' }}>
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/wp-content/themes/brikk-child/images/v.png`}
                                    alt="Icon"
                                  />
                                  {trip}
                                </li>
                              ))
                            ) : (
                              <li></li>
                            )}
                          </ul>
                        </ul>
                      </div>
                      <hr />
                      <div className="commonSidebarList">
                        {/* <h3>Less Suitable Trips</h3> */}
                        <ul>
                          {lessSuitableTripData.length > 0 ? (
                            lessSuitableTripData.map((trip, index) => (
                              <li key={index} style={{ direction: 'rtl' }}>
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/wp-content/themes/brikk-child/images/x.png`}
                                  alt="Icon"
                                />
                                {trip}
                              </li>
                            ))
                          ) : (
                            <li></li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="sideBarContact">
                      {userId == 0 ? (
                        <a
                          href={`${process.env.REACT_APP_API_URL}/login/?listing_id=${listingData.id}`}
                          className="leftSideBarLoginBtn"
                        >
                          לרכישה
                        </a>
                      ) : (
                        showBlur && (
                          <a
                            href={`${process.env.REACT_APP_API_URL}/add-to-cart.php?listing_id=${listingData.id}`}
                            className="leftSideBarLoginBtn"
                          >
                            לרכישה
                          </a>
                        )
                      )}

                      <Button
                        className="contactButton commonBlackButton w-100"
                        onClick={handleContactButtonClick}
                      >
                        צור קשר
                      </Button>
                      <p className="sidebarPrice mt-4">
                        {listingData?.acf.price_for_purchasing}₪
                      </p>
                    </div>

                    <div className="acess_rec_data acess_rec_data_mobile desktop">
                      <a href={`${process.env.REACT_APP_API_URL}/listing/long-weekend-in-barcelona/`} target="_blank">
                        <img src={EyeHeart} />
                        תכנון מלא לדוגמה
                      </a>
                    </div>
                  </div>
                  {/* </div> */}

                  {(!showBlur && listingData?.id !== 17147) && (

                    <SidebarCalendar
                      showCalendar={showCalendar}
                      handleCalendarButtonClick={handleCalendarButtonClick}
                      localizer={localizer}
                      events={events}
                      handleSelect={handleSelect}
                      handleSave={handleSave}
                      openCalendar={setShowCalendar}
                      setMainLoader={setIsLoading}
                      isLoading={isCalendarLoading}
                      setIsLoading={setIsCalendarLoading}
                    />
                  )}

                  {isModalOpen && (
                    <Modal
                      isOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      onSave={handleSave}
                      start={selectedSlot?.start}
                      otherFields={true}
                      name={name}
                      userId={userId}
                      isSidebarCalendar={true}
                      setMainLoader={setIsLoading}
                      openCalendar={setShowCalendar}
                      setIsCalendarLoading={setIsCalendarLoading}
                    />
                  )}

                  {isContactModalOpen && (
                    <ContactModal
                      isOpen={isContactModalOpen}
                      onClose={() => setIsContactModalOpen(false)}
                      data={listingData}
                      userId={userId}
                    // onSendMessage={handleSendMessage}
                    />
                  )}
                </div>
              </div>
            </div>

          </>
        )
      )}
    </Layout>
    )}
    </>
  );
};

export default SingleListing;
