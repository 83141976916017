// import { Link } from 'react-router-dom';
import CalendarTimeIcon from "../assets/images/calendar-time-icon.png";
import SheetIcon from "../assets/images/sheet-icon.png";
import CartIcon from "../assets/images/cart-icon.png";
import ItineraryMapIcon from "../assets/images/itinerary-map-icon.png";

const HomeHowWork = () => { 
    return (
        <div className='homeHowWork'>
            <div className='container'>
                <h2 className='mb-5'>איך זה עובד?</h2>
                <div className='row'>
                    <div className='col-3'>
                        <div className='homeHowWorkCol h-100'>
                            <div className='homeHowWorkIcon'>
                                <img src={CalendarTimeIcon} />
                            </div>
                            <h4 className="mt-3">בוחרים את החופשה</h4>
                            <p>בוחרים בשדות החיפוש את היעד אליו אתם נוסעים ואת משך זמן החופשה ולוחצים על חיפוש.</p>
                        </div>
                    </div> 
                    <div className='col-3'>
                        <div className='homeHowWorkCol h-100'>
                            <div className='homeHowWorkIcon'>
                            <img src={SheetIcon} />
                            </div>
                            <h4 className="mt-3">בוחרים תכנון מלא לחופשה</h4>
                            <p>מקבלים שלל מסלולים מתוכננים ליעד מהמומחים הכי גדולים בעולם הטרוול בישראל ומסננים לפי מחיר, תגובות, כמות המלצות ועוד לבחירת התכנון המושלם.</p>
                        </div>
                    </div> 
                    <div className='col-3'>
                        <div className='homeHowWorkCol h-100'>
                            <div className='homeHowWorkIcon'>
                            <img src={CartIcon} />
                            </div>
                            <h4 className="mt-3">נרשמים ומבצעים רכישה</h4>
                            <p>בכל תכנון תצפו בתקציר של המידע שתקבלו בנוסף למידע על הטרוולר שתכנן את המסלול. לאחר ההרשמה לאתר תוכלו לבצע רכישה והמידע המלא של התכנון יתגלה עבורכם ואתם מוכנים לצאת לדרך.</p>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='homeHowWorkCol h-100'>
                            <div className='homeHowWorkIcon'>
                            <img src={ItineraryMapIcon} />
                            </div>
                            <h4 className="mt-3">בוחרים, מזמינים וטסים</h4>
                            <p>מגלים את כל ההמלצות הכי שוות ביעד, מורידים מפת המלצות לנייד, משתמשים בלו”ז המומלץ של הטרוולר או בונים לו”ז משלנו בקליק. מקבלים תשובות לכל השאלות שחשוב לדעת ומזמינים בקלות את כל המלונות, האטרקציות, המסעדות ועוד לחופשה הקרובה שלכם.</p>
                        </div>
                    </div>                   
                </div>                 
            </div>
        </div>
    );
};

export default HomeHowWork;
