import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

export default function FAQs() {
    return (
        <div className='faqs pt-90'>
            <div className='container'>
                <h2 className='mb-4'>שאלות נפוצות</h2>
                <div className='faqsInner'>
                    <Accordion activeIndex={0}>
                        <AccordionTab header='מיהם הטרוולרז שמציעים Travel Kit עם תכנון חופשה מושלם?'>
                            <p className='m-0'>
                            התמונות השוות אלה מחופשה חלומית שאתם רואים באינסטגרם של המשפיענים שאתם עוקבים אחריהם, יכולות להיות שלכם בחופשה הקרובה :) בוחרים כמו מי אתם רוצים לטייל, והטיול שלו או שלה אצלכם עם כל ההמלצות והפנינות הנסתרות שלא כולם מכירים - כולל התייעצויות אם צריך. כל טרוולר שמציע תכנון מסלול הוא מומחה ליעד המבוקש שאושר לאחר שנבדק על ידי צוות האתר.
                            </p>
                        </AccordionTab>
                        <AccordionTab header='מה מקבלים לאחר הרכישה? מה כל Travel Kit כוללת?'>
                            <p className='m-0'>
                            בתוך תכנון הטיול שלנו תמצאו ה-כ-ל: החל ממסלולים, דרך אטרקציות מדוייקות לכל יום, מסעדות מעולות, איפה השופינג הכי שווה, כולל מפה עם כל הממלצות שתתחבר לכם לחשבון ה-Google Maps שלכם, לו"ז המלא לכל שעה ליצירת מסלול מושלם  ועד המלצה איפה הכי כדאי להחליף כסף, איך להתנייד באזור, איפה קונים סים ועוד. בנוסף, אחרי הרכישה, תוכלו לבנות מסלול שלכם מתוך ההמלצות והכניס הכל בקלות ליומן שבהמשך, תטיילו איתו ביעד.
                            </p>
                        </AccordionTab>
                        <AccordionTab header='איך מחפשים מסלולי טיול שמתאימים ליעד ולכמות הימים שאני מתכנן לטוס?'>
                        בפניכם 2 אפשרויות - 
                        <ul className='discStyle'>
                            <li><strong>פרטים על החופשה</strong> - אחרי שתבחרו בשורת החיפוש במעלה הדף את היעד ואת האזור או העיר שבו תטיילו ותגלו לנו לכמה ימים אתם מתכננים לטוס, תגלו עולם של מסלולים מותאמים, ייחודיים ומרגשים שנוצרו על ידי קהילת השותפים שלנו, הטרוולרז.</li>
                            <li><strong>טרוולר ספציפי</strong> - תוכלו לבחור ב- “חיפוש לפי טרוולר” ליד שורת החיפוש ולחפש Travel Kit עם מסלול חופשה מהמם שנוצר על ידי המומחים הגדולים ביותר לעולם ה-Travel ברשתות החברתיות.</li>                                              
                        </ul>
                        אחרי החיפוש תוכלו לסנן בקלות לפי מחיר או דירוג.
                        </AccordionTab>
                        <AccordionTab header='איך בוחרים בין המסלולים לאותו היעד שפורסמו על ידי טרוולרז שונים?'>
                            <p className='m-0'>
                            ראשית, לאחר החיפוש תוכלו לברור בקלות בין כל מסלולי הטיול על ידי לחיצה על הסימן של הספר שמופיע על גבי התמונה הראשית של כל טיול ולראות תקציר של כמות המידע וההמלצות שתקבלו כדי להשוות בין המסלולים שמציעים טרוולרז שונים. רוצים יותר מידע לפני שמבצעים רכישה? ניתן לקבל על ידי לחיצה על אחד המסלולים שמעניינים אתכם ואז לקרוא על תקציר הטיול שהטרוולרז מציעים ולקרוא על הטרוולר עצמו. בנוסף, ניתן למיין את התוצאות לפי מחיר או דירוג.
                            </p>
                        </AccordionTab>
                        <AccordionTab header='אחרי רכישה, האם ניתן לתת גישה לאדם נוסף?'>
                            <p className='m-0'>
                            בדף ה-Checkout שמציג את המוצר שאתם עומדים לרכוש,  לפני מעבר לתשלום יש בתחתית העמוד אפשרות להוסיף מספר מיילים שיקבלו גישה למוצר שרכשתם. הראשון בחינם (מלבדכם כמובן!) ומהשני בתוספת תשלום.
                            </p>
                        </AccordionTab>
                        <AccordionTab header='כמה זמן גישה למסלול הטיול מקבלים לאחר הרכישה?'>
                            <p className='m-0'>
                            אחרי רכישה, תהיה לכם גישה למשך חצי שנה למסלול הטיול לחופשה הקרובה שלכם.
                            </p>
                        </AccordionTab>
                        <AccordionTab header='האם ניתן ליצור קשר עם הטרוולר לפני הרכישה?'>
                            <p className='m-0'>
                            ניתן לפנות לטרוולר אם יש לכם שאלות על המוצר לפני הרכישה אם כי כל המידע הנחוץ מופיע בדף הטיול. זמן התגובה עלול להשתנות מטרוולר לטרוולר אך בד”כ קורה בתוך מספר שעות עד ימים בודדים.
                            </p>
                        </AccordionTab>
                        <AccordionTab header='למי מסלול הטיול יכול להתאים?'>
                            <p className='m-0'>
                            מספיק לטייל עם טקסט שהעתקתם לוואצאפ ושלחתם לעצמכם, די עם צילומי מסך מהרשתות החברתיות וכמה לינקים מגוגל כדי לנסות לחבר תכנון חופשה נורמלי שבסוף מתגלה כמאכזב אחרי כל הזמן שהשקעתם באיסוף מידע אינסופי שאינו בהכרח מתאים לכם. 

                            </p>
                        </AccordionTab>
                        <AccordionTab header='האם יש קופונים או הנחות?'>
                            <p className='m-0'>
                            מפעם לפעם, האתר או הטרוולרז יכולים להציע הנחות וקופונים למסלולי חופשה. בד”כ תוכלו להתעדכן ברשת החברתית של אותו טרוולר או לראות הטבות ישירות באתר.
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}
        

