const NisIcon = () => {
    return (
        <svg version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M167 486 c-55 -21 -103 -61 -134 -114 -24 -40 -28 -58 -28 -123 0
            -67 4 -81 30 -125 99 -159 326 -164 425 -8 101 160 -4 368 -192 380 -39 3 -77
            -1 -101 -10z m202 -43 c27 -17 58 -48 76 -77 25 -42 30 -59 30 -116 0 -57 -5
            -74 -30 -116 -19 -30 -49 -60 -79 -79 -71 -43 -161 -43 -232 0 -30 19 -60 49
            -79 79 -25 42 -30 59 -30 115 0 54 5 75 28 113 66 113 205 149 316 81z"></path>
            <path d="M140 250 l0 -130 53 0 c78 0 97 23 97 118 0 41 -4 72 -10 72 -6 0
            -10 -29 -10 -68 0 -81 -15 -102 -73 -102 l-37 0 0 120 c0 73 -4 120 -10 120
            -6 0 -10 -50 -10 -130z"></path>
            <path d="M232 357 c-18 -19 -22 -35 -22 -95 0 -41 4 -72 10 -72 6 0 10 29 10
            68 0 81 15 102 73 102 l37 0 0 -120 c0 -73 4 -120 10 -120 6 0 10 50 10 130
            l0 130 -53 0 c-43 0 -58 -5 -75 -23z"></path>
            </g>
        </svg>
    )
}

export default NisIcon;

