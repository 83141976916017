const NightLifeMapMarker = ({height=100, width=72, color="#000000"}) => {
    return (
        <div style={{backgroundColor: "#fff", borderRadius: "100%"}}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={width} height={height} viewBox="0 0 100.000000 100.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
                    <path d="M375 981 c-184 -54 -312 -184 -359 -368 -67 -258 100 -527 371 -597 258 -67 527 100 597 371 92 353 -261 696 -609 594z m20 -226 c43 -42 34 -55 -40 -55 -36 0 -65 -4 -65 -8 0 -4 18 -26 40 -47 22 -21 40 -43 40 -47 0 -4 -18 -8 -39 -8 -55 0 -90 32 -98 87 -5 37 -2 45 25 73 40 40 101 42 137 5z m345 17 c0 -4 -34 -45 -75 -90 -41 -45 -75 -85 -75 -87 0 -3 21 -2 48 1 l47 6 -67 -69 -68 -69 0 -113 c0 -90 3 -116 15 -125 12 -9 5 -11 -32 -12 -39 -1 -43 1 -25 9 21 10 22 16 22 126 l0 115 -52 53 -52 53 54 0 55 0 95 105 c87 97 110 117 110 97z m-131 -99 c-7 -10 -29 -35 -48 -55 -32 -35 -39 -38 -91 -38 -54 0 -57 1 -110 55 l-54 55 158 0 c149 0 158 -1 145 -17z m151 8 c0 -5 -16 -23 -35 -40 -26 -22 -46 -31 -71 -31 l-35 0 33 40 c28 33 39 40 71 40 20 0 37 -4 37 -9z"/>
                </g>
            </svg>
        </div>
    )
}

export default NightLifeMapMarker;