const AttractionMapMarker = ({height=100, width=72, color="#000000"}) => {
    return (
        <div style={{backgroundColor: "#fff", borderRadius: "100%"}}><svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={width} height={height} viewBox="0 0 100.000000 100.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
            <path d="M375 981 c-176 -51 -292 -162 -350 -334 -119 -356 224 -725 588 -631 187 48 323 184 371 371 91 352 -261 695 -609 594z m236 -281 c26 -32 39 -40 65 -40 20 0 44 -9 61 -24 28 -24 28 -24 31 -170 l3 -146 -29 -30 -30 -30 -211 0 c-207 0 -210 0 -238 24 -28 24 -28 24 -31 170 l-3 146 29 30 c22 22 40 30 65 30 27 0 40 7 66 40 l32 40 79 0 79 0 32 -40z"/>
            <path d="M469 600 c-45 -13 -68 -30 -84 -60 -38 -73 -3 -163 73 -190 37 -13 47 -13 84 0 80 28 113 123 68 195 -27 44 -93 70 -141 55z m90 -76 c12 -15 21 -38 21 -51 0 -30 -51 -83 -80 -83 -29 0 -80 53 -80 83 0 42 36 77 80 77 30 0 43 -6 59 -26z"/>
        </g>
        </svg></div>
    )
}

export default AttractionMapMarker;